import React from 'react'
import ReactExport from "react-export-excel"
import { Button, Tooltip } from 'antd'
import {
    FileExcelOutlined
} from "@ant-design/icons"
import moment from 'moment'
import 'moment/locale/es'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ dataSet }) => {
    return (
        <ExcelFile element={
            <>
            <Tooltip placement="top" title="Exportar a Excel" >
                <Button size="middle" style={{ marginRight: 5 }} type="primary" ghost>
                    <FileExcelOutlined />
                </Button>
            </Tooltip>
            </>
        }>
            <ExcelSheet data={dataSet} name="Tickets">
                <ExcelColumn label="id" value="id"/>
                <ExcelColumn label="técnico" value="nombreTecnico"/>
                <ExcelColumn label="cliente" value="nombreEmpresa"/>
                <ExcelColumn label="requerimiento" value="requerimiento"/>
                <ExcelColumn label="solución" value="solucion"/>
                <ExcelColumn label="fechaCrea" value={col => col.createdAt ? col.createdAt : 'Sin fecha'}/>
                <ExcelColumn label="fechaCierre" value={col => col.fechaCierre ? col.fechaCierre : 'Sin fecha'}/>
                <ExcelColumn label="fechaProgramacion" value={col => col.fechaProgramacion ? col.fechaProgramacion : 'Sin fecha'}/>
                <ExcelColumn label="tipo" value={col => col.tipo === 'ticketCorto' ? 'Ticket' : 'Bitácora'} />
                <ExcelColumn label="estado" value="estado" />
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExportExcel

import React, { useEffect, useState } from "react"
import { Form, Input, Row, Col, Card, Button, Select, Tooltip } from "antd"
import { sendServer, toast } from '../../utils/utils'
import { PlusCircleOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import EmpresaAdd from '../empresas/EmpresaAdd'
import { getSesion, getPropsSelect, getTitle } from '../../utils/utils'
import SendEmail from './SendEmail'
import moment from 'moment'
import 'moment/locale/es'

const { Option } = Select
const { TextArea } = Input

const BitacoraAdd = ({ flagShow, setFlagShow, dataUpd, setDataUpd }) => {

  const [form] = Form.useForm();
  const [empresas, setempresas] = useState([]);
  const [tecnicos, settecnicos] = useState([]);
  const [estados, setestados] = useState([]);
  const [flagMdEmpresaAdd, setflagMdEmpresaAdd] = useState(false);
  const [titulo, settitulo] = useState('');
  const [flagMetodo, setflagMetodo] = useState(Object.keys(dataUpd).length === 0 ? 'agregar' : 'actualizar');
  const [disabled, setdisabled] = useState(false);
  const sesion = getSesion();
  const [openEmail, setOpenEmail] = useState(false);
  
  const loadDataLocal = async (clienteDef) => {
    const { data: { empresas, tecnicos, estados } } = await sendServer(null, 'GET', 'master/bitacora/loadDataLocal');
    setempresas(empresas);
    settecnicos(tecnicos);
    setestados(estados);

    //pone datos por defecto cuando es argegar
    if (flagMetodo == 'agregar') {

      console.log('BITACORA ADD');
      settitulo('Nuevo ticket');
      const empresaDef = extraerCliente(clienteDef, empresas);
      const estadoDef = estados.find(item => item.nombre === 'activo');
      console.log(empresaDef)
      form.setFieldsValue({
        fechaProgramacion: moment().format('YYYY-MM-DD[T]HH:mm'),
        idEmpresa: empresaDef.id,
        idCreador: sesion.id,
        idTecnico: sesion.id,
        idEstado: estadoDef.id,
        prioridad: 'bajo',
      });
    } else {
      console.log('BITACORA UPD');
      disabledCampos();
      console.log(dataUpd)
      settitulo(`Editar ticket #${dataUpd.id}`);
      form.setFieldsValue({
        id: dataUpd.id,
        fechaProgramacion: dataUpd.fechaProgramacion.replace(' ', 'T'),
        idEmpresa: dataUpd.idEmpresa,
        idTecnico: dataUpd.idTecnico,
        idEstado: dataUpd.idEstado,
        requerimiento: dataUpd.requerimiento,
        solucion: dataUpd.solucion,
        nota: dataUpd.nota,
        prioridad: dataUpd.prioridad,
        creadoPor: dataUpd.creadoPor
      });
    }

  }

  /**
   * agrega otros valores en caso de cierta condición
   * @param {*} item 
   */
  const othersValues = (item) => {
    if (flagMetodo === 'agregar') {
      if (item.prioridad === 'alto' || item.prioridad === 'medio') {
        item.flagSms = true;
      } 
    } else {
      delete item.creadoPor;
    }
  }

  const extraerCliente = (clienteDef, empresas) => {
    if (clienteDef) {
      const [cliente] = clienteDef;
      return cliente;
    } else {
      return empresas.find(item => item.nombre === 'general')
    }
  }

  const disabledCampos = () => {
    const { estado } = dataUpd;
    if (estado === 'finalizado' || estado === 'anulado') {
      setdisabled(true);
    }
  }

  useEffect(() => {
    loadDataLocal();
  }, [flagShow])

  const handleSubmit = async (item) => {
    try {
      setdisabled(true);
      const flagValid = validFields(item);
      if (flagValid === 'ok') {
        othersValues(item);
        console.log(item);
        const { data } = await sendServer(item, 'POST', `master/bitacora/${flagMetodo}`);
        toast('success', 'enexusapp', `${flagMetodo === 'agregar' ? 'Agregado' : 'Actualizado'} correctamente, Ticket #${data.id}`);
        setdisabled(false);
        validSendEmail();
      } else {
        toast('warning', 'enexusapp', flagValid);
      }

    } catch (e) {
      const { status, data } = e;
      console.log(e)
      if (data === 'algunCampoDuplicado') {
        toast('warning', 'enexusapp', `No se puede ${flagMetodo}, usuario duplicado`);
      } else if (data === 'The given data was invalid.') {
        toast('warning', 'enexusapp', 'Faltan campos obligatorios');
      } else {
        toast('warning', 'enexusapp', `No se puede ${flagMetodo} - ${data}`);
      }
    }

  };

  /**
   * enviar correo de manera opcional despúes de finalizar
   */
  const validSendEmail = () => {
    const { children: estado } = getPropsSelect(form.getFieldInstance('idEstado'));
    if(flagMetodo === 'actualizar' && estado === 'finalizado') {
      setOpenEmail(true);
    } else {
      handleBack();
    }
  }

  /**
   * hace setEstadoTikect para validSendEmail
   * @param {*} item 
   * @returns 
   */
  const validFields = (item) => {
    const { children: estado } = getPropsSelect(form.getFieldInstance('idEstado'));
    if (estado === 'finalizado') {
      if (!item.solucion) {
        setdisabled(false);
        return 'Debe haber una solución para finalizar el Ticket';
      }
    }
    return 'ok';
  }

  const handleBack = () => {
    setFlagShow(true);
    setDataUpd({});
  }

  return (
    <>
     <Button type="default" 
          onClick={handleBack} 
          icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
          style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
      </Button>
      <Card
        title={titulo}
        bordered
        style={{ boxShadow: "0px 8px 20px #00000033" }}
        className="misCards"
      >
        <Form
          name="bitacora_add"
          onFinish={handleSubmit}
          form={form}
        >
          <Row gutter="24">

            {/* ID */}
            <Form.Item
              name="id"
              rules={[
                { required: false },
              ]}
              hidden={true}
            >
              <Input />
            </Form.Item>

            {/* CREADOR */}
            <Form.Item
              name="idCreador"
              rules={[
                { required: false },
              ]}
              hidden={true}
            >
              <Input />
            </Form.Item>


            {/* FECHA PROGRAMACION */}
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                label={"Fecha programación"}
                labelCol={{ span: 24 }}
                name="fechaProgramacion"
                rules={[
                  { required: true, message: "Por favor ingresa la fecha!" },
                ]}
              >
                <Input type="datetime-local" style={{ width: '100%', height: '31px' }} onMouseEnter={getTitle}/>
              </Form.Item>
            </Col>


            {/* PRIORIDAD */}
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="prioridad"
                label="Prioridad"
                labelCol={{ span: 24 }}
                hasFeedback
                rules={[{ required: true, message: 'Por favor ingrese la prioridad!' }]}
              >
                <Select
                  size="middle"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione el prioridad"
                >
                  {
                    window.prioridades.map((op) => (
                      op.valor !== 'todos' ?
                        <Option key={op.valor} value={op.valor}>
                          {op.nombre}
                        </Option>
                        : undefined
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {/* EMPRESA */}
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="idEmpresa"
                label="Cliente"
                labelCol={{ span: 24 }}
                hasFeedback
                validateStatus="success"
                rules={[{ required: true, message: 'Por favor selecione la empresa!' }]}
              >
                <Select
                  suffixIcon={
                    <Tooltip placement="topLeft" title="Agregar cliente" color='blue'>
                      <PlusCircleOutlined style={{ color: '#1890ff' }} onClick={() => setflagMdEmpresaAdd(true)} />
                    </Tooltip>
                  }
                  size="middle"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una empresa"
                >
                  {
                    empresas.map((op) => (
                      <Option key={op.id} value={op.id}>
                        {op.nombre}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {/* TECNICO */}
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="idTecnico"
                label="Responsable"
                labelCol={{ span: 24 }}
                hasFeedback
                validateStatus="success"
                rules={[{ required: true, message: 'Por favor selecione el Responsable!' }]}
              >
                <Select
                  suffixIcon={
                    sesion?.rol === 'admin' ?
                      <Tooltip placement="topLeft" title="Agregar Técnico" color='blue'>
                        <PlusCircleOutlined style={{ color: '#1890ff' }} /* onClick={() => setflagMdEmpresaAdd(true)} */ />
                      </Tooltip>
                      : undefined
                  }
                  size="middle"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione un Técnico"
                  disabled={sesion?.rol === 'admin' ? false : true}
                >
                  {
                    tecnicos.map((op) => (
                      <Option key={op.id} value={op.id}>
                        {op.nombres}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {
              flagMetodo === 'actualizar' ?
                <>
                  {/* Creador */}
                  <Col xs={24} sm={24} md={6}>
                    <Form.Item
                      label={"Creado X"}
                      labelCol={{ span: 24 }}
                      name="creadoPor"
                      rules={[
                        { required: false },
                      ]}
                    >
                      <Input disabled size="middle" />
                    </Form.Item>
                  </Col>
                </>
                : undefined
            }

            {/* ESTADO */}
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="idEstado"
                label="Estado"
                labelCol={{ span: 24 }}
                hasFeedback
                validateStatus="success"
                rules={[{ required: true, message: 'Por favor selecione el estado!' }]}
              >
                <Select
                  suffixIcon={
                    <Tooltip placement="topLeft" title="Agregar Estado" color='blue'>
                      <PlusCircleOutlined style={{ color: '#1890ff' }} /* onClick={() => setflagMdEmpresaAdd(true)} */ />
                    </Tooltip>
                  }
                  size="middle"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione un estado"
                >
                  {
                    estados.map((op) => (
                      <Option key={op.id} value={op.id}>
                        {op.nombre}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {
              flagMetodo === 'actualizar' ?
                <Col xs={24} sm={24} md={6}></Col>
                : <Col xs={24} sm={24} md={12}></Col>
            }


            {/* REQUERIMIENTO */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={"Requerimiento"}
                labelCol={{ span: 24 }}
                name="requerimiento"
                rules={[
                  { required: true, message: "Por favor ingresa el requerimiento!" },
                ]}
              >
                <TextArea placeholder="Requerimiento" rows={4} />
              </Form.Item>
            </Col>

            {/* SOLUCION */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={"Solución"}
                labelCol={{ span: 24 }}
                name="solucion"
                rules={[
                  { required: false, message: "Por favor ingresa la solucion!" },
                ]}
              >
                <TextArea placeholder="Solución" rows={4} />
              </Form.Item>
            </Col>

            {/* NOTA */}
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                label={"Nota"}
                labelCol={{ span: 24 }}
                name="nota"
                rules={[
                  { required: false, message: "" },
                ]}
              >
                <TextArea placeholder="Nota" rows={4} />
              </Form.Item>
            </Col>


            <Col xs={24} sm={24} md={24} style={{ padding: 15 }}></Col>

            <div>
              <Button type="primary" disabled={disabled} className="mybutton" size="middle" onClick={form.submit} >
                Guardar
              </Button>

              <Button type="default" className="mybutton" onClick={handleBack} size="middle">
                Cancelar
              </Button>
            </div>

          </Row>
        </Form>
      </Card>
      
      {/* componente para agregar clientes */}
      <EmpresaAdd
        visible={flagMdEmpresaAdd}
        setVisible={setflagMdEmpresaAdd}
        loadData={loadDataLocal}
        tipoDef={'requerimientos'}
      />

      {/* componente para enviar email antes de cerrar */}
      <SendEmail openEmail={openEmail} setOpenEmail={setOpenEmail} dataUpd={dataUpd} handleBack={handleBack} />
    </>
  );
};

export default BitacoraAdd;

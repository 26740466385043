import React, { useState, useEffect } from 'react'
import { Image, Row, Col, Button, Modal, Input } from 'antd'
import { sendServer, toast } from '../../utils/utils'
import {
    PhoneOutlined,
    MessageOutlined
} from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"

const WppQr = () => {

    const [random, setRandom] = useState();
    const [mdPrueba, setmdPrueba] = useState(false);

    const handleSubmit = async () => {
        try {
            const { data } = await sendServer(null, 'GET', `master/bitacora/connectWpp`);
            toast('success', 'enexusapp', `¡Generado correctamente! tienes un minuto para vincular el dispositivo`);
        } catch (e) {
            toast('info', 'enexusapp', 'No se pudo generar el QR');
        }
    }

    const sendWppPrueba = async () => {
        try {
            const item = {
                celular: document.querySelector('#txtCelular').value,
                text: document.querySelector('#txtMensaje').value,
            }
            if(!item.celular || !item.text) {
                toast('info', 'enexusapp', 'Debe haber celular y mesaje');
                return
            }
            const { data } = await sendServer(item, 'POST', `master/bitacora/sendWppTest`);
            toast('success', 'enexusapp', `¡Mensaje enviado correctamente!`);
        } catch (e) {
            toast('info', 'enexusapp', `No se pudo enviar ${e.data}`);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setRandom(Date.now());
            console.log('RELOAD IMG')
        }, 1000);
        return () => {
            console.log('CHAO QR');
            clearInterval(interval);
        }
    }, [])

    const handleBack = () => {
        window.history.back();
    }

    return (
        <>
            <Button type="default" 
                onClick={handleBack} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>
            <Row gutter={[16, 16]}>
                <Col span={9} />
                <Col span={6} >
                    <Image
                        preview={false}
                        src={`${window.wppQr}?${random}`}
                        width={'100%'}
                    />
                </Col>
                <Col span={9} />
                <Col span={24} type="flex" align="middle">
                    <Button type="default" className="mybutton" onClick={handleSubmit} size="large">
                        Generar QR
                    </Button>
                    <Button type="default" className="mybutton" onClick={() => setmdPrueba(true)} size="large">
                        Enviar mensaje
                    </Button>
                </ Col>
            </Row>
            <Modal 
                title="Enviar Whatsapp de prueba" 
                visible={mdPrueba}
                onOk={sendWppPrueba} 
                onCancel={() => setmdPrueba(false)}
                okText="Enviar"
                cancelText="Cancelar"
            >
               <Input 
                    style={{marginBottom: 10}}
                    id="txtCelular"
                    prefix={<PhoneOutlined />}
                    placeholder="Celular"
                />
                <Input 
                    id="txtMensaje"
                    prefix={<MessageOutlined />}
                    placeholder="Mensaje de prueba"
                />
            </Modal>
        </>
    )
}

export default WppQr

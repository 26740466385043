import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Select } from 'antd'
import { sendServer, toast } from '../../utils/utils'
import { getSesion } from '../../utils/utils'
const { Option } = Select

const Filtro = ({ visible, setVisible, loadData, filtro }) => {

    const [form] = Form.useForm();
    const [estados, setEstados] = useState([])

    const loadDataLocal = async () => {
        const { data } = await sendServer(null, 'GET', 'master/tareas/listarEstados');
        setEstados(data.map(d => <Option key={d.id}>{d.nombre}</Option>));
    }

    useEffect(() => {
      if(visible) {
        loadDataLocal();
        console.log('HIJO FILTRO');
      }
    }, [visible])

    const handleSubmit = async (item) => {
        try {
            console.log(item)
            const { id, rol } = getSesion();
            if(rol === 'admin') {
                filtro.query = 'todosXEstado';
                filtro.estado = item.estado;
            } else {
                filtro.query = 'responsableXestado';
                filtro.estado = item.estado;
            }
            loadData();
            setVisible(false);
        } catch (e) {
            const { status, data } = e;
            toast('warning', 'enexusapp', `No se puede filtrar - ${data}`);
        }

    };

    return (
        <Modal
            title="Filtro tareas"
            visible={visible}
            width={1000}
            onOk={form.submit}
            onCancel={() => { setVisible(false) }}
            destroyOnClose={true}
            okText={"Filtrar"}
            cancelText={"Cancelar"}
        >
            <Form
                name="filtro"
                onFinish={handleSubmit}
                form={form}
            >
                <Row gutter="24">

                    <Col xs={24} sm={24} md={24}>
                        <Form.Item
                            name="estado"
                            label="Estado"
                            labelCol={{ span: 24 }}
                            hasFeedback
                            rules={[{ required: true, message: 'Por favor ingrese el estado!' }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                placeholder="Seleccione el estado"
                                optionFilterProp="children"
                            >
                                {estados}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default Filtro

import { useState } from 'react'

export default function useToken() {

  const getToken = () => {
    const tokenString = localStorage.getItem('sesionEnexus');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem('sesionEnexus', JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
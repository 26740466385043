import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import {
    Form,
    Input,
    Row,
    Col,
    Select,
    Card,
    Collapse,
    Divider,
    Space,
    Button,
} from "antd"
import { sendServer, toast, getSesion, savePropSesion } from '../../../utils/utils'
import {
    MinusCircleOutlined,
    PlusOutlined
} from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
const { Option } = Select
const { Panel } = Collapse
const { TextArea } = Input

const HvPcsAdd = ({ props }) => {

    const [form] = Form.useForm();
    const history = useHistory();
    const [empresas, setEmpresas] = useState([]);
    const [areas, setAreas] = useState([]);
    const [metodo, setMetodo] = useState('');
    const [dataUpd, setDataUpd] = useState({});
    const [sesion, setSesion] = useState(getSesion());
    const [isActiveForm, setIsActiveForm] = useState(false);

    const handleSubmit = async (item) => {
        try {
            console.log(item);
            modificaAntesDeEnviar(item);
            const { data } = await sendServer(item, 'POST', `master/hv/${metodo}`);
            toast('success', 'enexusapp', 'Guardado correctamente');
            savePropSesion('idEmpresa', item.idEmpresa);
            history.push('/hv-pcs');
        } catch (e) {
            const { status, data } = e;
            toast('info', 'enexusapp', `No se puede guardar - ${data}`);
        }

    };

    const modificaAntesDeEnviar = (item) => {
        const { computedMatch: { params: { idHvPcs } } } = props;
        if(idHvPcs != -1) {
            if(!item.memoriaRam) {
                item.memoriaRam = dataUpd.memoriaRam;
            }
            if(!item.perifericos) {
                item.perifericos = dataUpd.perifericos;
            }
        }
        item.estado = true;
    }

    const loadData = async () => {
        try {
            const { data: empresas } = await sendServer(null, 'GET', `master/empresa/listar/true/null/null/null`);
            const { data: areas } = await sendServer(null, 'GET', `master/area/listar`);
            setEmpresas(empresas);
            setAreas(areas);
        } catch (error) {
        }
    }

    const loadOtherData = async () => {
        const { computedMatch: { params: { idHvPcs } } } = props;
        const sesion = getSesion();
        const metodo = idHvPcs == -1 ? 'agregar' : 'actualizar';
        setMetodo(metodo);
        if (metodo === 'actualizar') {
            loadData();
            const { data } = await sendServer(null, 'GET', `master/hv/listar/getId/${idHvPcs}/null`);
            const [hv] = data;
            setDataUpd(hv);
            form.setFieldsValue({
                ...hv,
                idUsuarioCrea: sesion.id,
                idUsuarioEdita: sesion.id
            });
            if (!hv) {
                setMetodo('agregar');
            }
            console.log(hv);
        } else {
            form.setFieldsValue({
                idUsuarioCrea: sesion.id,
                idUsuarioEdita: sesion.id,
            });
        }
    }

    const isClient = () => {
        const { rol } = getSesion();
        if(rol === 'cliente') {
            setIsActiveForm(true);
        }
    }

    const handleBack = () => {
        history.push('/hv-pcs');
    }

    useEffect(() => {
        loadOtherData();
        loadData();
        isClient();
        return () => { }
    }, [])

    return (
        <>
            <Button type="default"
                onClick={handleBack}
                icon={<FontAwesomeIcon icon={faHandPointLeft} />}
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>
            <Card
                bordered={true}
                style={{ backgroundColor: "#e6f7ff" }}
                className="misCards"
            >
                <Form
                    name="hv_add"
                    onFinish={handleSubmit}
                    form={form}
                    autoComplete="off"
                >
                    <Row gutter="24">

                        {/* id */}
                        <Form.Item
                            name="id"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>

                        {/* idUsuarioCrea */}
                        <Form.Item
                            name="idUsuarioCrea"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>

                        {/* idUsuarioEdita */}
                        <Form.Item
                            name="idUsuarioEdita"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>

                        <Divider dashed>INFO GENERAL</Divider>

                        {/* empresa */}
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item
                                name="idEmpresa"
                                label="Empresa"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: '¡Valor obligatorio!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione una empresa"
                                    disabled={isActiveForm}
                                >
                                    {
                                        empresas.map((op) => (
                                            <Option key={op.id} value={op.id}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* area */}
                        <Col xs={24} sm={24} md={6}>
                            <Form.Item
                                name="idArea"
                                label="Area/Departamento"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: '¡Valor obligatorio!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Area/departamento"
                                    disabled={isActiveForm}
                                >
                                    {
                                        areas.map((op) => (
                                            <Option key={op.id} value={op.id}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* Hostname */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Hostname"}
                                labelCol={{ span: 24 }}
                                name="hostname"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Hostname" />
                            </Form.Item>
                        </Col>

                        {/* marca */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Marca"}
                                labelCol={{ span: 24 }}
                                name="marca"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Marca" />
                            </Form.Item>
                        </Col>

                        {/* modelo */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Modelo"}
                                labelCol={{ span: 24 }}
                                name="modelo"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Modelo" />
                            </Form.Item>
                        </Col>

                        {/* serial */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Serial"}
                                labelCol={{ span: 24 }}
                                name="serial"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Serial" />
                            </Form.Item>
                        </Col>

                        {/* placa */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Placa"}
                                labelCol={{ span: 24 }}
                                name="placa"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="placa" />
                            </Form.Item>
                        </Col>

                        {/* usuario */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Usuario/sesión"}
                                labelCol={{ span: 24 }}
                                name="usuarioSesion"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Usuario/sesión" />
                            </Form.Item>
                        </Col>

                        {/* contraseña */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Contraseña/sesión"}
                                labelCol={{ span: 24 }}
                                name="contrasenaSesion"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Contraseña/sesión" />
                            </Form.Item>
                        </Col>

                        <Divider dashed>CPU</Divider>

                        {/* marca */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Marca"}
                                labelCol={{ span: 24 }}
                                name="marcaCpu"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Marca" />
                            </Form.Item>
                        </Col>

                        {/* generacion */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Generación"}
                                labelCol={{ span: 24 }}
                                name="generacionCpu"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Generación" />
                            </Form.Item>
                        </Col>

                        {/* valocidad */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Valocidad"}
                                labelCol={{ span: 24 }}
                                name="velocidadCpu"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Valocidad" />
                            </Form.Item>
                        </Col>

                        {/* Nucleos */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"#Nucleos"}
                                labelCol={{ span: 24 }}
                                name="nucleosCpu"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="#Nucleos" />
                            </Form.Item>
                        </Col>

                        <Divider dashed>BOARD</Divider>

                        {/* marca */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Marca"}
                                labelCol={{ span: 24 }}
                                name="marcaBoard"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Marca" />
                            </Form.Item>
                        </Col>

                        {/* modelo */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Modelo"}
                                labelCol={{ span: 24 }}
                                name="modeloBoard"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Modelo" />
                            </Form.Item>
                        </Col>

                        {/* Generación */}
                        <Col xs={24} sm={6} md={6}>
                            <Form.Item
                                label={"Generación"}
                                labelCol={{ span: 24 }}
                                name="generacionBoard"
                                rules={[
                                    { required: false, message: "¡Valor obligatorio!" },
                                ]}
                            >
                                <Input disabled={isActiveForm} size="large" placeholder="Generación" />
                            </Form.Item>
                        </Col>

                        {/* NOTA */}
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item
                                label={"Nota"}
                                labelCol={{ span: 24 }}
                                name="nota"
                                rules={[
                                    { required: false, message: "" },
                                ]}
                            >
                                <TextArea disabled={isActiveForm} placeholder="Nota" rows={4} />
                            </Form.Item>
                        </Col>

                        <Divider dashed style={{ marginBottom: 25 }}>DETALLES</Divider>

                        <Col xs={24} sm={24} md={24}>
                            <Collapse>
                                <Panel header="Memoria RAM" key="1">
                                    <Form.List name="memoriaRam" >
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Row gutter="24">
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'marcaMemoria']}
                                                                    fieldKey={[fieldKey, 'marcaMemoria']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Marca" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'serialMemoria']}
                                                                    fieldKey={[fieldKey, 'serialMemoria']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Serial" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'velocidadRam']}
                                                                    fieldKey={[fieldKey, 'velocidadRam']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Velocidad" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'generacionRam']}
                                                                    fieldKey={[fieldKey, 'generacionRam']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Generación" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'busRam']}
                                                                    fieldKey={[fieldKey, 'busRam']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Bus" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                {sesion.rol !== 'cliente'?<MinusCircleOutlined onClick={() => remove(name)} />:undefined}
                                                            </Col>
                                                            <Divider/>
                                                        </Row>
                                                    </Space>
                                                ))}
                                                {sesion.rol !== 'cliente'? 
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                            Nueva memoria
                                                        </Button>
                                                    </Form.Item>
                                                    :undefined
                                                }

                                            </>
                                        )}
                                    </Form.List>
                                </Panel>
                                <Panel header="Perifericos internos" key="2">
                                    <Form.List name="perifericos" >
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                    <Space key={key} style={{ marginBottom: 8 }} align="baseline">
                                                        <Row gutter="24">
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'nombrePeriferico']}
                                                                    fieldKey={[fieldKey, 'nombrePeriferico']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Nombre" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'marcaPeriferico']}
                                                                    fieldKey={[fieldKey, 'marcaPeriferico']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Marca" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'serialPeriferico']}
                                                                    fieldKey={[fieldKey, 'serialPeriferico']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Serial" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'caracteristicaPeriferico']}
                                                                    fieldKey={[fieldKey, 'caracteristicaPeriferico']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Caracteristica" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'fechaInstalacionPeriferico']}
                                                                    fieldKey={[fieldKey, 'fechaInstalacionPeriferico']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} type="date" placeholder="Fecha instalación" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'notaPeriferico']}
                                                                    fieldKey={[fieldKey, 'notaPeriferico']}
                                                                    rules={[{ required: false, message: '¡Valor obligatorio!' }]}
                                                                >
                                                                    <Input disabled={isActiveForm} placeholder="Nota" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={6} md={6}>
                                                                {sesion.rol !== 'cliente'?<MinusCircleOutlined onClick={() => remove(name)} />:undefined}
                                                            </Col>
                                                            <Divider/>
                                                        </Row>
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    {sesion.rol !== 'cliente'? 
                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Nuevo periférico
                                                        </Button>
                                                    </Form.Item>
                                                    :undefined
                                                }
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Panel>
                            </Collapse>
                        </Col>

                        <Col xs={24} sm={24} md={24} style={{ padding: 15 }}></Col>

                        {
                            sesion.rol !== 'cliente' ?
                                <div>
                                    <Button type="primary" /* disabled={disabled} */ className="mybutton" size="large" onClick={form.submit} >
                                        Guardar
                                    </Button>

                                    <Button type="default" className="mybutton" onClick={handleBack} size="large">
                                        Cancelar
                                    </Button>
                                </div>
                                : undefined
                        }

                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default HvPcsAdd

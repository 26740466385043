import logo from "../../img/logo.jpg"
import "./styles/template.css"
import "antd/dist/antd.css"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Layout, Menu, Breadcrumb, Row, Col, Tooltip, Avatar, Drawer } from "antd"
import { sendServer, getSesion, logout, confirm } from '../../utils/utils'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  ApiOutlined,
  WhatsAppOutlined,
  FileDoneOutlined,
  ToolOutlined,
  BarChartOutlined,
  UserOutlined,
  WifiOutlined,
  PaperClipOutlined
} from "@ant-design/icons"
import { faAddressBook, faDollyFlatbed } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const Template = (props) => {

  const { component: Component, titulo, token } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [rol, setRol] = useState('user');
  const [isNetwork, setIsNetwork] = useState('green');

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const showUserName = () => {
    const { nombres } = getSesion();
    return nombres;
  }

  const handleLogout = () => {
    confirm('Salir', '¿Desea salir de la aplicación?', logout)
  }

  const sessionInterval = () => {
    //valido la sesion
    const timer = setInterval(() => {
      try {
        sendServer(null, "GET", `user/validarSesion/${token.email}`)
          .then((res) => {
            const { data } = res;
            const [user] = data;
            if (!user) {
              logout();
            }
            if (user.estado != true) {
              logout();
            }
            if (token.clave !== user.clave) {
              logout();
            }
          })
          .catch((e) => console.log(e));
      } catch (e) {
        console.log(e);
      }
    }, 30000);
  }

  const networkInterval = () => {
    setInterval(() => {
      fetch('https://www.google.com/', {
        mode: 'no-cors',
      })
        .then(() => {
          console.log('CONNECTED TO INTERNET');
          setIsNetwork('green');
        }).catch(() => {
          console.log('INTERNET CONNECTIVITY ISSUE');
          setIsNetwork('red');
        })
    }, 5000);
  }

  useEffect(() => {
    sessionInterval();
    networkInterval();
    const { rol } = getSesion();
    setRol(rol);
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Drawer
        title={
          <Link to="/bitacora" className="brand-link">
            <img
              className="logo"
              alt="example"
              src={logo}
              onClick={onCollapse}
            />
          </Link>
        }
        placement="left"
        onClose={onCollapse}
        visible={collapsed}
      >
        <Menu theme="dark" mode="inline">
          <SubMenu key="sub1" icon={<FontAwesomeIcon icon={faDollyFlatbed} style={{ fontSize: 20 }} />} title="Inventario" className="myfont">
            <Menu.Item key="1" className="myfont" onClick={onCollapse} ><Link to="/hv-pcs" className="brand-link">PCS</Link></Menu.Item>
          </SubMenu>
          {rol !== 'cliente' ?
            <>
              <SubMenu key="sub2" icon={<BarChartOutlined style={{ fontSize: 20 }} />} title="Informes" className="myfont">
                <Menu.Item key="2" className="myfont" onClick={onCollapse} ><Link to="/informe-mensual" className="brand-link">Mensual</Link></Menu.Item>
              </SubMenu>

              <Menu.Item key="3" className="myfont" onClick={onCollapse} icon={<FontAwesomeIcon icon={faAddressBook} style={{ fontSize: 20 }} />}><Link to="/clientes" className="brand-link">Clientes</Link></Menu.Item>

              <SubMenu key="sub3" icon={<ToolOutlined style={{ fontSize: 20 }} />} title="HelpDesk" className="myfont">
                <Menu.Item key="4" className="myfont" onClick={onCollapse}><Link to="/bitacora" className="brand-link">Tickets</Link></Menu.Item>
                {token?.modulos?.map(mod => {
                  if (mod.nombreModulo === 'ticketCorto') {
                    return <><Menu.Item key="5" className="myfont" onClick={onCollapse}><Link to="/ticketcorto" className="brand-link">Bitácora</Link></Menu.Item></>
                  }
                })}
              </SubMenu>

              <Menu.Item key="6" className="myfont" onClick={onCollapse} icon={<FileDoneOutlined style={{ fontSize: 20 }} />}><Link to="/tareas" className="brand-link">Tareas</Link></Menu.Item>
              {
                rol === 'admin' ?
                  <>
                    <Menu.Item key="7" className="myfont" onClick={onCollapse} icon={<UserOutlined style={{ fontSize: 20 }} />}><Link to="/usuarios" className="brand-link">Usuarios</Link></Menu.Item>
                    {/* <Menu.Item key="8" className="myfont" onClick={onCollapse} icon={<WhatsAppOutlined style={{ fontSize: 20 }} />}><Link to="/whatsapp" className="brand-link">WhatsApp</Link></Menu.Item> */}
                  </>
                  : undefined
              }
            </>
            : undefined
          }


        </Menu>
      </Drawer>

      <Layout className="site-layout">
        <Header className="site-layout-background myHeader" style={{ padding: 0, position: 'fixed', zIndex: 1, width: '100%' }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger btnTrigger',
            onClick: onCollapse,
          })}

          <div style={{ float: 'right', marginRight: '15px' }}>

            <WifiOutlined style={{
              verticalAlign: 'middle',
              marginRight: 20,
              fontSize: 23,
              color: isNetwork
            }} size="large" gap={1} />

            {
              rol !== 'cliente' ?
                <><Tooltip placement="topLeft" title="Tickets">
                  <Link to="/bitacora" style={{ color: 'black' }}>
                    <ToolOutlined style={{ cursor: 'pointer', verticalAlign: 'middle', marginRight: 20, fontSize: 18 }} size="large" gap={1} />
                  </Link>
                </Tooltip>
                  {token?.modulos?.map(mod => {
                    if (mod.nombreModulo === 'ticketCorto') {
                      return <>
                        <Tooltip placement="topLeft" title="Bítacora">
                          <Link to="/ticketcorto" style={{ color: 'black' }}>
                            <PaperClipOutlined style={{ cursor: 'pointer', verticalAlign: 'middle', marginRight: 20, fontSize: 18 }} size="large" gap={1} />
                          </Link>
                        </Tooltip>
                      </>
                    }
                  })}
                </>
                : undefined
            }

            <Tooltip title={showUserName}>
              <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle', marginRight: 20 }} size="default" gap={1}>
                {token.nombres.slice(0, 1)?.toUpperCase()}
              </Avatar>
            </Tooltip>

            <Tooltip placement="topLeft" title="Salir">
              <ApiOutlined style={{ cursor: 'pointer', verticalAlign: 'middle', marginRight: 10, fontSize: 18 }} size="large" gap={1} onClick={handleLogout} />
            </Tooltip>

          </div>
        </Header>

        <Content style={{ margin: "0 16px" }} className="myContent">

          <Breadcrumb className='patchTitulo'>
            <Breadcrumb.Item><HomeOutlined style={{ marginTop: 20, marginBottom: 10 }} /></Breadcrumb.Item>
            <Breadcrumb.Item>{titulo}</Breadcrumb.Item>
          </Breadcrumb>

          <Row className="site-layout-background">
            <Col span={24}>
              <Component props={props}></Component>
            </Col>
          </Row>
        </Content>

        <Footer style={{ textAlign: "center" }}>
          Copyright 2021 Todos los derechos reservados 7.0.1
        </Footer>

      </Layout>
    </Layout>
  );
};

export default Template;

import React, { useState } from 'react'
import Tareas from './Tareas'
import TareaAdd from './TareaAdd'

const Index = () => {

    const [flagShow, setFlagShow] = useState(true);
    const [dataUpd, setDataUpd] = useState({});
    
    return (
        flagShow ?
            <Tareas flagShow={flagShow} setFlagShow={setFlagShow} setDataUpd={setDataUpd}></Tareas>
        : <TareaAdd flagShow={flagShow} setFlagShow={setFlagShow} dataUpd={dataUpd} setDataUpd={setDataUpd}></TareaAdd>
    )
}

export default Index

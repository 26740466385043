import React, { useState, useEffect } from "react"
import MUIDataTable from "mui-datatables"
import { Button, Tag, Tooltip } from 'antd'
import { PlusCircleOutlined, ReloadOutlined, FilterOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import moment from 'moment'
import 'moment/locale/es'
import { sendServer, getSesion } from '../../utils/utils'
import Filtro from './Filtro'

function Bitacora({ setFlagShow, setDataUpd }) {
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("600px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [flagMdFiltro, setFlagMdFiltro] = useState(false);
    const [data, setData] = useState([]);
    const [filtro, setfiltro] = useState({
        query: null,
        idTecnico: null,
        estado: null,
        flag: null
    });

    useEffect(() => {
        const { id, rol } = getSesion();
        if (rol === 'admin') {
            filtro.query = 'defaultAdmin';
        } else {
            filtro.query = 'defaultTecnico';
            filtro.idTecnico = id;
        }
        loadData();
        othersStyles();
        return () => {}
    }, [])

    const othersStyles = () => {
        try {
            document.querySelector('.MuiTablePagination-toolbar > p').style = 'display: none';
        } catch (error) {
            
        }
    }

    const options = {
        //tableBodyHeight,
        filter: true,
        filterType: "dropdown",
        responsive,
        //tableBodyMaxHeight,
        print: false,
        download: false,
        customToolbar: () => (
            <>
                {"  "}
                <Tooltip title="Filtrar desde servidor">
                    <Button onClick={() => { setFlagMdFiltro(true) }}>
                        <FilterOutlined />
                    </Button>
                </Tooltip>
                {"  "}
                <Tooltip title="Sincronizar">
                    <Button onClick={() => {
                        const { id, rol } = getSesion();
                        if (rol === 'admin') {
                            filtro.query = 'defaultAdmin';
                            filtro.estado = null;
                            filtro.flag = null;
                        } else {
                            filtro.query = 'defaultTecnico';
                            filtro.estado = null;
                            filtro.idTecnico = id;
                            filtro.flag = null;
                        }
                        loadData()
                    }}><ReloadOutlined /></Button>
                </Tooltip>
                {"  "}
                <Tooltip title="Nuevo ticket">
                    <Button onClick={() => { setFlagShow(false) }}>
                        <PlusCircleOutlined />
                    </Button>
                </Tooltip>
            </>
        ),
        onRowClick: rowData => upd(rowData),
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: false
    };

    const loadData = async () => {
        try {
            setData([]);
            const { data } = await sendServer(null, 'GET', `master/bitacora/listar/${filtro.query}/${filtro.idTecnico}/${filtro.estado}/${filtro.flag}`);
            setData(data);
        } catch (error) {
        }
    }

    const upd = async (data) => {
        console.log(data)
        const [id] = data;
        const { data: dataServer } = await sendServer(null, 'GET', `master/bitacora/getById/${id}`);
        const [dataFull] = dataServer;
        setDataUpd(dataFull);
        setFlagShow(false);
    }

    const handleBack = () => {
        window.history.back();
    }

    const columns = [
        {
            name: "id",
            label: "#ticket",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "nombreEmpresa",
            label: "cliente",
            options: {
                sort: true,
            }
        },
        {
            name: "requerimiento",
            label: "requerimiento",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "fechaProgramacion",
            label: "programación",
            options: {
                sort: true,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const [flag] = value.split(';');
                    const [,fecha] = value.split(';');
                    if(flag === 't') {
                        return (
                            <Tag color="magenta">{fecha}</Tag>
                        )
                    } else {
                        return (
                            <Tag color="blue">{fecha}</Tag>
                        )
                    }
                }
            }
        },
        {
            name: "prioridad",
            label: "prioridad",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value === 'alto') {
                        return (
                            <Tag color="#ff1a1a">{value.toUpperCase()}</Tag>
                        )
                    } else if (value === 'medio') {
                        return (
                            <Tag color="#f50">{value.toUpperCase()}</Tag>
                        )
                    } else if (value === 'bajo') {
                        return (
                            <Tag color="#108ee9">{value.toUpperCase()}</Tag>
                        )
                    }
                }
            }
        },
        {
            name: "estado",
            label: "estado",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value === 'activo') {
                        return (
                            <Tag color="#f50">{value.toUpperCase()}</Tag>
                        )
                    } else if (value === 'finalizado') {
                        return (
                            <Tag color="#87d068">{value.toUpperCase()}</Tag>
                        )
                    } else if (value === 'anulado') {
                        return (
                            <Tag color="#ff4d4d">{value.toUpperCase()}</Tag>
                        )
                    } else {
                        return (
                            <Tag color="#108ee9">{value.toUpperCase()}</Tag>
                        )
                    }
                }
            }
        },
        {
            name: "nombreTecnico",
            label: "responsable",
            options: {
                sort: true,
            }
        }
    ];

    return (
        <>
            <Button type="default" 
                onClick={handleBack} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>
            <MUIDataTable
                className="bitacotaTable"
                title={"Lista de Tickets"}
                data={data}
                columns={columns}
                options={options}
            />
            <Filtro
                visible={flagMdFiltro}
                setVisible={setFlagMdFiltro}
                loadData={loadData}
                filtro={filtro}
            />
        </>
    );
}

export default Bitacora

import React, { useEffect } from 'react'
import { Modal, Input, Form, Row, Col } from 'antd'
import { sendServer } from '../../utils/utils'


const SendEmail = ({ openEmail, setOpenEmail, dataUpd, handleBack }) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            correo: dataUpd.correoEmpresa
        })
        return () => { }
    }, [openEmail])

    const sendEmailServer = async (item) => {
        try {
            dataUpd.listaCorreos = item.correo;
            const { data } = await sendServer(dataUpd, 'POST', `master/bitacora/sendEmailCliente`);
        } catch (e) {
           console.log(e)
        }
        setOpenEmail(false);
        handleBack();
    };

    const onCancel = () => {
        setOpenEmail(false);
        handleBack();
    }

    return (
        <Modal
            title="Envío de correo (opcional)"
            visible={openEmail}
            onOk={form.submit}
            onCancel={onCancel}
            okText="Enviar"
            cancelText="Cancelar"
        >
            <Form
                name="enviar_correo"
                onFinish={sendEmailServer}
                form={form}
            >
                <Row gutter="24">
                    {/* Email */}
                    <Col xs={24} sm={24} md={24}>
                        <Form.Item
                            name="correo"
                            rules={[
                                { required: true, message: "Por favor ingresa el coorreo!" },
                            ]}
                        >
                            <Input size="large" placeholder="Correo" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}


export default SendEmail;
import { Modal, notification } from 'antd'
import { ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons"
import download from 'downloadjs'
import request from 'axios'
import moment from 'moment'
import 'moment/locale/es'

const baseUrl = process.env.REACT_APP_BASE_API;

const logout = async () => {
  try {
    const { email } = getSesion();
    localStorage.removeItem('sesionEnexus');
    window.location = "/";
    fetch(`${baseUrl}/api/logout/${email}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
  } catch (e) {
  }
}

/**
 * Recupera la sesion
 * @returns 
 */
const getSesion = () => {
  const sesionString = localStorage.getItem('sesionEnexus');
  const userToken = JSON.parse(sesionString);
  return userToken;
}

/**
 * agrega una propiedad a la sesion
 * @param {*} name 
 * @param {*} value 
 */
const savePropSesion = (name, value) => {
  const sesion = getSesion();
  sesion[name] = value;
  localStorage.setItem('sesionEnexus', JSON.stringify(sesion));
}

/**
 * Cambia color
 * @param {*} id 
 * @param {*} color 
 */
const changeColor = (id, color) => {
  const element = document.querySelector(id);
  element.style.backgroundColor = color;
}

/**
 * mensaje tipo toast
 * @param {*} type 
 * @param {*} message 
 * @param {*} description 
 */
const toast = (type, message, description) => {
  notification[type]({ message, description });
};

/**
 * Mensaje tipo confirm
 * @param {*} title 
 * @param {*} content 
 * @param {*} callcack 
 */
const confirm = (title, content, callcack) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: 'Si',
    cancelText: 'No',
    onOk() {
      callcack();
    },
  });
}

/**
 * 
 * @param {*} title 
 * @param {*} content 
 * @param {*} callcack 
 */
const confirmIconOk = (title, content, callcack) => {
  Modal.confirm({
    title,
    icon: <CheckOutlined />,
    content,
    okText: 'Si',
    cancelText: 'No',
    onOk() {
      callcack();
    },
  });
}

/**
 * Solo para hacer login
 * @param {*} data 
 * @returns 
 */
const sendServerLogin = async (data) => {

  const result = await request({
    method: "POST",
    url: `${baseUrl}/api/login`,
    data,
    headers: {
      "Content-Type": "application/json",
    },
  })
  return result?.data;
};

/**
 * Envia al server cualquier cosa
 * @param {*} data 
 * @param {*} method 
 * @param {*} action 
 * @returns 
 */
const sendServer = async (data, method, action) => {

  const { token } = getSesion() || '';
  try {
    const result = await request({
      method,
      url: `${baseUrl}/api/${action}`,
      data,
      headers: {
        'access-token': `${token}`,
        "Content-Type": "application/json",
      },
    })
    return result?.data;
  } catch (e) {
    const msg = e.message;
    if (msg.includes('401')) {
      toast('error', 'enexuspp', 'sesión invalida');
      logout();
      console.log(msg)
    } else {
      throw (e.response.data);
    }
  }
};

const getPropsSelect = (item) => {
  const { props: { children, value } } = item;
  const res = children.find(d => d.key == value);
  const { props: resProps } = res;
  return resProps;
}

const isMovile = () => {
  if (window.navigator.userAgent.match(/Android/i)
    || window.navigator.userAgent.match(/webOS/i)
    || window.navigator.userAgent.match(/iPhone/i)
    || window.navigator.userAgent.match(/iPad/i)
    || window.navigator.userAgent.match(/iPod/i)
    || window.navigator.userAgent.match(/BlackBerry/i)
    || window.navigator.userAgent.match(/Windows Phone/i)) {
    return true;
  } else {
    return false;
  }

}

const getDataSelect = (data) => {
  //console.log(data);
  const { rowIndex, tableData } = data;
  let res = {};
  if (tableData) {
    for (let i = 0; i < tableData.length; i++) {
      const row = tableData[i];
      if (rowIndex === i) {
        res = row;
        return res;
      }
    }
  }
  return res;
}

const getTitle = (input) => {
  const { target: { defaultValue, id } } = input;
  const date = moment(defaultValue).format('YYYY/MM/DD HH:mm a');
  document.getElementById(id)
    .title = date;
}

/**
 * 
 * @param {*} id 
 */
const downloadPdf = (id) => {
  const nameFile = moment().format('YYYY-MM-DDHH:mm:ss');
  const { token } = getSesion() || '';
  request.get(`${baseUrl}/api/master/hv/generarPDF/${id}`, {
    headers: {
      'access-token': `${token}`,
    },
    responseType: 'blob', 
  })
  .then(response => {
    const content = response.headers['content-type'];
    download(response.data, `Hoja de vida ${nameFile}`, content);
  })
  .catch(error => console.log(error));
}

export {
  sendServerLogin,
  changeColor,
  sendServer,
  confirm,
  toast,
  getSesion,
  logout,
  getPropsSelect,
  isMovile,
  getDataSelect,
  getTitle,
  savePropSesion,
  downloadPdf,
  confirmIconOk
};

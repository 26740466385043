import React, { useState, useEffect } from "react"
import MUIDataTable from "mui-datatables"
import { Button, Tooltip } from 'antd'
import { PlusCircleOutlined, ReloadOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import { sendServer, confirmIconOk, toast } from '../../utils/utils'
import TicketAdd from './TicketAdd'

function TicketCorto() {

    const [responsive, setResponsive] = useState("vertical");
    const [data, setData] = useState([]);
    const [flagMdTicket, setflagMdTicket] = useState(false);
    const [dataUpd, setdataUpd] = useState({});

    useEffect(() => {
        loadData();
        othersStyles();
        return () => {}
    }, [])

    const othersStyles = () => {
        try {
            document.querySelector('.MuiTablePagination-toolbar > p').style = 'display: none';
        } catch (error) {
            
        }
    }

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive,
        print: false,
        download: false,
        filter: false,
        viewColumns:false,
        customToolbar: () => (
            <>
                {"  "}
                <Tooltip title="Sincronizar">
                    <Button onClick={loadData}><ReloadOutlined /></Button>
                </Tooltip>
                {"  "}
                <Tooltip title="Nueva bitácora">
                    <Button onClick={() => { add() }}>
                        <PlusCircleOutlined />
                    </Button>
                </Tooltip>
            </>
        ),
        //onRowClick: rowData => upd(rowData),
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: false
    };

    const loadData = async () => {
        try {
            const { data } = await sendServer(null, 'GET', `master/ticketCorto/listar`);
            setData(data);
        } catch (error) {
        }
    }

    const upd = async (data) => {
        const [id] = data;
        const { data: dataServer } = await sendServer(null, 'GET', `master/ticketCorto/listarXId/${id}`);
        const [dataFull] = dataServer;
        console.log(dataFull);
        setdataUpd(dataFull);
        setflagMdTicket(true);
    }

    const add = () => {
        setdataUpd({});
        setflagMdTicket(true);
    }

    const updEstado = async (row) => {
        try {
            confirmIconOk('enexusapp', '¿Desea finalizar? ', async () => {
                const [id] = row;
                const item = {
                    id,
                    idEstado: 4
                }
                const { data } = await sendServer(item, 'POST', `master/ticketCorto/actualizar`);
                toast('success', 'enexusapp', 'Guardado correctamente');
                loadData();
            });
        } catch (error) {
            toast('info', 'enexusapp', error.message);
        }
    }

    const handleBack = () => {
        window.history.back();
    }

    const columns = [
        {
            name: "id",
            label: "#",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "nombreEmpresa",
            label: "empresa",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "clienteTemporal",
            label: "cliente temporal",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "quienSolicita",
            label: "solícita",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "requerimiento",
            label: "requerimiento",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "solucion",
            label: "solución",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "nombreTecnico",
            label: "responsable",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "accion",
            label: "acción",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                        <Button 
                            onClick={() => {
                                const { rowData } = tableMeta;
                                upd(rowData) 
                            }} 
                            icon={<EditOutlined />}
                            style={{width: 50}}
                        >
                        </Button>
                        {" "}
                        <Button 
                            onClick={() => {
                                const { rowData } = tableMeta;
                                updEstado(rowData) 
                            }} 
                            icon={<CheckOutlined />}
                            style={{width: 50}}
                        >
                        </Button>
                        </>
                    )
                   
                }
            }
        },
    ];

    return (
        <>
            <Button type="default" 
                onClick={handleBack} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>
            <MUIDataTable
                className="ticketCortoTable"
                title={"Bitácora"}
                data={data}
                columns={columns}
                options={options}
                state={false}
            />

            <TicketAdd
                visible={flagMdTicket}
                setVisible={setflagMdTicket}
                dataUpd={dataUpd}
                loadData={loadData}
            />
        </>
    );
}

export default TicketCorto

import React, { useState, useEffect } from 'react'
import { sendServer, toast, confirm } from '../../utils/utils'
import { Button, Tag, Tooltip, Select } from 'antd'
import MaterialTable, { MTableToolbar } from 'material-table'
import { makeStyles } from "@material-ui/core"
import {
    PlusCircleOutlined,
    ReloadOutlined,
    FilterOutlined,
    ApiOutlined
} from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import UsuarioAdd from './UsuarioAdd'
import UsuarioUpd from './UsuarioUpd'
const { Option } = Select



const Usuarios = () => {
    const useStyles = makeStyles({
        table: {
            '& tr:nth-child(even)': {
                background: '#dedede',
            },
        },
    });
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [state, setState] = useState(true);
    const [flagMdAdd, setFlagMdAdd] = useState(false);
    const [flagMdUpd, setFlagMdUpd] = useState(false);
    const [dataUpd, setDataUpd] = useState({});
    const [filtro, setFiltro] = useState({
        estado: true,
    })

    const loadData = async () => {
        try {
            setState(true);
            const { data } = await sendServer(null, 'GET', `user/listarFiltro/${filtro.estado}`);
            setData(data);
            setState(false);
        } catch (error) {
            setState(false);
        }
    }


    useEffect(() => {
        loadData();
        return () => {}
    }, [])

    const upd = (_, data) => {
        setDataUpd(data);
        setFlagMdUpd(true);
    }

    const del = async (id) => {
        try {
            confirm('enexusapp', 'Desea eliminar? esta acción no se puede revertir', async () => {
                const { msg } = await sendServer(null, 'DELETE', `master/empresa/eliminar/${id}`);
                toast('success', 'enexusapp', 'Anulado correctamente');
                loadData();
            });
        } catch (e) {
            toast('error', 'enexusapp', 'No se puede anular');
            console.log(e);
        }
    }

    const handleBack = () => {
        window.history.back();
    }

    const sincronizar = () => {
        filtro.estado =  true;
        loadData();
    }

    const hadleFiltro = () => {
        filtro.estado = !filtro.estado;
        loadData();
    }

    /**
     * cierra todas sesiones activas
     */
    const handleCloseAll = () => {
        try {
            confirm('enexusapp', '¿Desea cerrar todas las sesiones?', async () => {
                const { msg } = await sendServer(null, 'GET', `user/closeAllSessions`);
            });
        } catch (e) {
            toast('error', 'enexusapp', 'No se pueden cerrar sesiones');
            console.log(e);
        }
    }

    const columns = [
        {
            title: 'nombres',
            field: 'nombres'
        },
        {
            title: 'rol',
            field: 'rol',
        },
        {
            title: 'email',
            field: 'email',
        },
        {
            title: 'celular',
            field: 'celular',
        },
        {
            title: 'email asignado',
            field: 'emailAsignado',
        },
        {
            title: 'empresa',
            field: 'nombreEmpresa',
        },
        {
            title: 'estado',
            field: 'estado',
            render: fila =>
                <>
                    {fila.estado
                        ? <Tag color="success">activo</Tag>
                        : <Tag color="error">anulado</Tag>
                    }
                </>

        },
    ]

    return (
        <div className={classes.table}>
            <Button type="default" 
                onClick={handleBack} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>

            <MaterialTable
                title="Lista de usuarios"
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                onRowClick={upd}
                options={{
                    headerStyle: { 
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px', marginBottom: 10 }}>
                                <Tooltip placement="top" title="Agregar" >
                                    <Button size="middle" onClick={() => setFlagMdAdd(true)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <PlusCircleOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={(sincronizar)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Cerrar todas las sesiones">
                                    <Button size="middle" onClick={handleCloseAll}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ApiOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Filtrar">
                                    <Button size="middle" onClick={hadleFiltro}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <FilterOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
            />

            <UsuarioAdd
                visible={flagMdAdd}
                setVisible={setFlagMdAdd}
                loadData={loadData}
            />

            <UsuarioUpd
                visible={flagMdUpd}
                setVisible={setFlagMdUpd}
                loadData={loadData}
                dataUpd={dataUpd}
            /> 
        </div>
    )
}

export default Usuarios

import React, { useState, useEffect } from "react"
import MUIDataTable from "mui-datatables"
import { Button, Tooltip } from 'antd'
import { PlusCircleOutlined, ReloadOutlined, FilterOutlined } from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import { sendServer, getSesion } from '../../utils/utils'
import Filtro from './Filtro'

function Tareas({ setFlagShow, setDataUpd }) {
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("600px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [flagMdFiltro, setFlagMdFiltro] = useState(false);
    const [data, setData] = useState([]);
    const [filtro, setfiltro] = useState({
        query: null,
        idResponsable: null,
        estado: null,
        flag: null
    });

    useEffect(() => {
        const { id, rol } = getSesion();
        if (rol === 'admin') {
            filtro.query = 'defaultAdmin';
        } else {
            filtro.query = 'defaultResponsable';
            filtro.idResponsable = id;
        }
        loadData();
        othersStyles();
        return () => {}
    }, [])

    const othersStyles = () => {
        try {
            const paginator = document.querySelector('.MuiTablePagination-toolbar > p');
            paginator.style = 'display: none';
            console.log(paginator)
        } catch (error) {
            
        }
    }

    const options = {
        //tableBodyHeight,
        filter: true,
        filterType: "dropdown",
        responsive,
        //tableBodyMaxHeight,
        print: false,
        download: false,
        customToolbar: () => (
            <>
                {"  "}
                <Tooltip title="Filtrar desde servidor">
                    <Button onClick={() => { setFlagMdFiltro(true) }}>
                        <FilterOutlined />
                    </Button>
                </Tooltip>
                {"  "}
                <Tooltip title="Sincronizar">
                    <Button onClick={() => {
                        const { id, rol } = getSesion();
                        if (rol === 'admin') {
                            filtro.query = 'defaultAdmin';
                            filtro.estado = null;
                            filtro.flag = null;
                        } else {
                            filtro.query = 'defaultResponsable';
                            filtro.estado = null;
                            filtro.idResponsable = id;
                            filtro.flag = null;
                        }
                        loadData()
                    }}><ReloadOutlined /></Button>
                </Tooltip>
                {"  "}
                <Tooltip title="Nueva tareas">
                    <Button onClick={() => { setFlagShow(false) }}>
                        <PlusCircleOutlined />
                    </Button>
                </Tooltip>
            </>
        ),
        onRowClick: rowData => upd(rowData),
        selectableRowsHideCheckboxes: true,
        selectableRowsOnClick: false
    };

    const loadData = async () => {
        try {
            setData([]);
            const { data } = await sendServer(null, 'GET', `master/tareas/listar/${filtro.query}/${filtro.idResponsable}/${filtro.estado}/${filtro.flag}`);
            setData(data);
        } catch (error) {
        }
    }

    const upd = async (data) => {
        console.log(data)
        const [id] = data;
        const { data: dataServer } = await sendServer(null, 'GET', `master/tareas/getById/${id}`);
        const [dataFull] = dataServer;
        setDataUpd(dataFull);
        setFlagShow(false);
    }

    const handleBack = () => {
        window.history.back();
    }

    const columns = [
       {
            name: "id",
            label: "#tarea",
            options: {
                sort: true,
                filter: false,
            }
        },
        {
            name: "nombreEmpresa",
            label: "cliente",
            options: {
                sort: true,
            }
        },
        {
            name: "clienteTemporal",
            label: "cliente temporal",
            options: {
                sort: true,
            }
        },
        {
            name: "tarea",
            label: "tarea",
            options: {
                sort: true,
                filter: false,
            }
        },
    ];

    return (
        <>
            <Button type="default" 
                onClick={handleBack} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>
            <MUIDataTable
                className="hoverTask"
                title={"Lista de Tareas"}
                data={data}
                columns={columns}
                options={options}
            />
            <Filtro
                visible={flagMdFiltro}
                setVisible={setFlagMdFiltro}
                loadData={loadData}
                filtro={filtro}
            />
        </>
    );
}

export default Tareas

import React, { useState, useEffect } from 'react'
import { sendServer, toast, confirm, getSesion } from '../../utils/utils'
import { Button, Tag, Tooltip, Modal, Form, Row, Col, Select } from 'antd'
import MaterialTable, { MTableToolbar } from 'material-table'
import { makeStyles } from "@material-ui/core"
import {
    PlusCircleOutlined,
    ReloadOutlined,
    FilterOutlined
} from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import EmpresaAdd from './EmpresaAdd'
import EmpresaUpd from './EmpresaUpd'
const { Option } = Select



const Empresas = () => {
    const useStyles = makeStyles({
        table: {
            '& tr:nth-child(even)': {
                background: '#dedede',
            },
        },
    });
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [state, setState] = useState(true);
    const [flagMdAdd, setFlagMdAdd] = useState(false);
    const [flagMdUpd, setFlagMdUpd] = useState(false);
    const [dataUpd, setDataUpd] = useState({});
    const [flagRol, setflagRol] = useState(false);
    const [flagMdFiltro, setflagMdFiltro] = useState(false);
    const [formFiltro] = Form.useForm();
    const [estados, setestados] = useState([
        {
            nombre: 'activo',
            valor: 'activo'
        },
        {
            nombre: 'anulado',
            valor: 'anulado'
        }
    ])

    const [filtro, setfiltro] = useState({
        estado: true,
        nombre: null,
        nit: null,
        tipo: null
    })

    const loadData = async () => {
        try {
            setState(true);
            const { data } = await sendServer(null, 'GET', `master/empresa/listar/${filtro.estado}/${filtro.nombre}/${filtro.nit}/${filtro.tipo}`);
            setData(data.filter(d => d.nombre !== 'general'));
            setState(false);
        } catch (error) {
            setState(false);
        }
    }

    const loadConfig = () => {
        const sesion = getSesion();
        setflagRol(sesion.rol === 'admin' ? true : false);
        formFiltro.setFieldsValue({
            estado: 'activo',
            tipo: 'contratos'

        })
    }

    useEffect(() => {
        Promise.all([
            loadData(),
            loadConfig(),
            console.log('PADRE')
        ]).then(() => {
            //setIsLoading(false)
        })
        return (() => {
            //setIsLoading(true)
        })
    }, [])

    const upd = (data) => {
        setDataUpd(data);
        setFlagMdUpd(true);
    }

    const del = async (id) => {
        try {
            if (flagRol) {
                confirm('enexusapp', 'Desea eliminar? esta acción no se puede revertir', async () => {
                    const { msg } = await sendServer(null, 'DELETE', `master/empresa/eliminar/${id}`);
                    toast('success', 'enexusapp', 'Anulado correctamente');
                    loadData();
                });
            }
        } catch (e) {
            toast('error', 'enexusapp', 'No se puede anular');
            console.log(e);
        }
    }

    /**
     * 
     * @param {*} item 
     */
    const filterServer = async (item) => {
        filtro.estado = item.estado === 'activo' ? true : false;
        filtro.nombre = null;
        filtro.nit = null;
        filtro.tipo = item.tipo === 'todos' ? null : item.tipo;
        console.log(filtro)
        loadData();
        setflagMdFiltro(false);
    }

    const handleBack = () => {
        window.history.back();
    }

    const sincronizar = () => {
        filtro.estado =  true;
        filtro.nombre = null;
        filtro.nit = null;
        filtro.tipo = null;
        loadData()
    }

    const columns = [
        {
            title: 'tipo',
            field: 'tipo'
        },
        {
            title: 'nombres',
            field: 'nombre',
        },
        {
            title: 'documento',
            field: 'nit',
        },
        {
            title: 'correo',
            field: 'correo',
        },
        {
            title: 'teléfono',
            field: 'telefono',
        },
        {
            title: 'dirección',
            field: 'direccion',
        },
        {
            title: 'estado',
            field: 'estado',
            render: fila =>
                <>
                    {fila.estado
                        ? <Tag color="success">activo</Tag>
                        : <Tag color="error">anulado</Tag>
                    }
                </>

        },
    ]

    return (
        <div className={classes.table}>
            <Button type="default" 
                onClick={handleBack} 
                icon={<FontAwesomeIcon icon={faHandPointLeft} />} 
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>

            <MaterialTable
                title="Lista de clientes"
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                localization={{
                    header: {
                        actions: 'Acción',
                    },
                }}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: { 
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                actions={[
                    {
                        icon: 'edit',
                        iconProps: { style: { color: "#40a9ff" } },
                        tooltip: 'Editar',
                        onClick: (event, rowData) => upd(rowData)
                    },
                    {
                        icon: 'delete',
                        iconProps: { style: { color: "#ff4d4f" } },
                        tooltip: 'Eliminar',
                        onClick: (event, rowData) => del(rowData.id),
                    }
                ]}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px', marginBottom: 10 }}>
                                <Tooltip placement="top" title="Agregar" >
                                    <Button size="middle" onClick={() => setFlagMdAdd(true)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <PlusCircleOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={(sincronizar)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Filtrar">
                                    <Button size="middle" onClick={() => setflagMdFiltro(true)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <FilterOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
            />

            <EmpresaAdd
                visible={flagMdAdd}
                setVisible={setFlagMdAdd}
                loadData={loadData}
            />

            <EmpresaUpd
                visible={flagMdUpd}
                setVisible={setFlagMdUpd}
                loadData={loadData}
                dataUpd={dataUpd}
            />

            {/* MODAL FILTRO */}
            <Modal
                title="Filtro clientes"
                visible={flagMdFiltro}
                width={1000}
                onOk={formFiltro.submit}
                onCancel={() => { setflagMdFiltro(false) }}
                destroyOnClose={true}
                okText={"Filtrar"}
                cancelText={"Cancelar"}
            >
                <Form
                    name="filtro"
                    onFinish={filterServer}
                    form={formFiltro}
                >
                    <Row gutter="24">
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="estado"
                                label="Estado"
                                labelCol={{ span: 24 }}
                                hasFeedback
                                rules={[{ required: true, message: 'Por favor ingrese el estado!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione el estado"
                                >
                                    {
                                        estados.map((op) => (
                                            <Option key={op.valor} value={op.valor}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                name="tipo"
                                label="Tipo"
                                labelCol={{ span: 24 }}
                                hasFeedback
                                rules={[{ required: true, message: 'Por favor ingrese el tipo!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione el tipo"
                                >
                                    {
                                        window.tiposCliente.map((op) => (
                                            <Option key={op.valor} value={op.valor}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default Empresas

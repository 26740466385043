import "./App.css"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import PrivateRoute from './routes/PrivateRoute'
import PublicRoute from './routes/PublicRoute'
import Login from './views/login/Login'
import { Home, Empresas, Bitacora, WppQr, Tareas, InformeMensual, HvPcsAdd, HvPcs, Usuarios, TicketCorto } from './views/index'
import useToken from './components/hooks/useToken'

function App() {
  
  const {token, setToken} = useToken();

  return (
    <BrowserRouter>
      <Switch>

        {/* public routes */}
        <PublicRoute restricted={false} component={Login} path="/" exact/>

        {/* private routes */}
        <PrivateRoute component={Home} titulo={"Home"} path="/home" token={token} ruta={'home'} exact/>
        <PrivateRoute component={Empresas} titulo={"Clientes"} path="/clientes" token={token} ruta={'clientes'} exact/>
        <PrivateRoute component={Bitacora} titulo={"Tickets"} path="/bitacora" token={token} ruta={'bitacora'} exact/>
        <PrivateRoute component={WppQr} titulo={"Whatsapp"} path="/whatsapp" token={token} ruta={'whatsapp'} exact/>
        <PrivateRoute component={Tareas} titulo={"Tareas"} path="/tareas" token={token} ruta={'tareas'} exact/>
        <PrivateRoute component={InformeMensual} titulo={"Informe mensual"} path="/informe-mensual" token={token} ruta={'informe-mensual'} exact/>
        <PrivateRoute component={HvPcs} titulo={"Hojas de vida PCS"} path="/hv-pcs" token={token} ruta={'hv-pcs'} exact/>
        <PrivateRoute component={HvPcsAdd} titulo={"Hojas de vida PCS"} path="/hv-editar-pcs/:idHvPcs" token={token} ruta={'hv-editar-pcs'} exact/>
        <PrivateRoute component={Usuarios} titulo={"Usuarios"} path="/usuarios" token={token} ruta={'usuarios'} exact/>
        <PrivateRoute component={TicketCorto} titulo={"Bitácora"} path="/ticketcorto" token={token} ruta={'ticketCorto'} exact/>

        {/* pae not found */}
        <Route render={() => 
          window.location="/bitacora"
        } />

      </Switch>
    </BrowserRouter>
  );
}

export default App;

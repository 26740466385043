import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Select, Tooltip } from "antd"
import { sendServer, toast, getSesion } from '../../utils/utils'
import EmpresaAdd from '../empresas/EmpresaAdd'
import {
  CheckOutlined,
  CloseOutlined,
  PlusCircleOutlined
} from "@ant-design/icons"
const { Option } = Select
const { TextArea } = Input

const TicketAdd = ({ visible, setVisible, loadData, dataUpd }) => {

  const [form] = Form.useForm();
  const [empresas, setempresas] = useState([]);
  const [flagMdEmpresaAdd, setflagMdEmpresaAdd] = useState(false);
  const metodo= dataUpd.id?'actualizar':'agregar';

  useEffect(() => {
    if(visible) {
      loadDataLocal();
      afterSave();
      if(dataUpd) {
        form.setFieldsValue({...dataUpd});
      }
    }
  }, [visible])

  const loadDataLocal = async () => {
    const { data: { empresas } } = await sendServer(null, 'GET', 'master/bitacora/loadDataLocal');
    setempresas(empresas);
  }

  const afterSave = () => {
    const sesion = getSesion();
    form.resetFields();
    form.setFieldsValue({
      idCreador: sesion.id,
      idTecnico: sesion.id,
      idEstado: 1,
      idEmpresa: '1'
    })
  }

  const handleSubmit = async (item) => {
    try {
      console.log(item);
      const { data } = await sendServer(item, 'POST', `master/ticketCorto/${metodo}`);
      loadData();
      toast('success', 'enexusapp', 'Guardado correctamente');
      if(metodo === 'actualizar') {
        setVisible(false);
      } else {
        afterSave();
      }
    } catch (e) {
      const { status, data } = e;
      if (data === 'algunCampoDuplicado') {
        toast('warning', 'enexusapp', 'No se puede guardar, algun campo duplicado');
      } else if (data === 'The given data was invalid.') {
        toast('warning', 'enexusapp', 'Faltan campos obligatorios');
      } else {
        toast('warning', 'enexusapp', `No se puede guardar - ${data}`);
      }
    }

  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  const handleSave = ({ charCode }) => {
    if (charCode === 13) {
      form.submit();
    }
  }

  return (
    <>
      <Modal
        title="Guardar bitácora"
        visible={visible}
        width={1000}
        onOk={form.submit}
        onCancel={() => { closeModal() }}
        okText={"Guardar"}
        cancelText={"Cancelar"}
        destroyOnClose
      >
        <Form
          name="ticket_add"
          onFinish={handleSubmit}
          form={form}
        >
          <Row gutter="24">

            {/* ID */}
            <Form.Item
              name="id"
              rules={[
                { required: false },
              ]}
              hidden={true}
            >
              <Input />
            </Form.Item>

            {/* CREADOR */}
            <Form.Item
              name="idCreador"
              rules={[
                { required: true },
              ]}
              hidden={true}
            >
              <Input />
            </Form.Item>

            {/* TECNICO */}
            <Form.Item
              name="idTecnico"
              rules={[
                { required: true },
              ]}
              hidden={true}
            >
              <Input />
            </Form.Item>

            {/* ESTADO */}
            <Form.Item
              name="idEstado"
              rules={[
                { required: true },
              ]}
              hidden={true}
            >
              <Input />
            </Form.Item>

            {/* EMPRESA */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name="idEmpresa"
                label="Cliente"
                labelCol={{ span: 24 }}
                hasFeedback
                validateStatus="success"
                rules={[{ required: true, message: '¡Valor obligatorio!' }]}
              >
                <Select
                  suffixIcon={
                    <Tooltip placement="topLeft" title="Agregar cliente" color='blue'>
                      <PlusCircleOutlined style={{ color: '#1890ff' }} onClick={() => setflagMdEmpresaAdd(true)} />
                    </Tooltip>
                  }
                  autoFocus
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Cliente"
                >
                  {
                    empresas.map((op) => (
                      <Option key={op.id} value={op.id}>
                        {op.nombre}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            {/* CLIENTE TAMPORAL */}
            <Col xs={24} sm={6} md={6}>
              <Form.Item
                label={"Cliente temporal"}
                labelCol={{ span: 24 }}
                name="clienteTemporal"
                rules={[
                  { required: false },
                ]}
              >
                <Input size="large" placeholder="Cliente temporal" onKeyPress={handleSave}/>
              </Form.Item>
            </Col>

             {/* QUIEN SOLICITA */}
             <Col xs={24} sm={6} md={6}>
              <Form.Item
                label={"Quien solícita"}
                labelCol={{ span: 24 }}
                name="quienSolicita"
                rules={[
                  { required: false },
                ]}
              >
                <Input size="large" placeholder="Quien solícita" onKeyPress={handleSave}/>
              </Form.Item>
            </Col>

            {/* REQUERIMIENTO */}
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label={"Requerimiento"}
                labelCol={{ span: 24 }}
                name="requerimiento"
                rules={[
                  { required: true, message: "'¡Valor obligatorio!'" },
                ]}
              >
                <TextArea placeholder="Requerimiento" rows={4} onKeyPress={handleSave}/>
              </Form.Item>
            </Col>

            {/* SOLUCION */}
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label={"Solución"}
                labelCol={{ span: 24 }}
                name="solucion"
                rules={[
                  { required: false },
                ]}
              >
                <TextArea placeholder="Solución" rows={4} onKeyPress={handleSave} />
              </Form.Item>
            </Col>

            {/* NOTA */}
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                label={"Nota"}
                labelCol={{ span: 24 }}
                name="nota"
                rules={[
                  { required: false },
                ]}
              >
                <TextArea placeholder="Nota" rows={4} onKeyPress={handleSave}/>
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </Modal>

      {/* componente para agregar clientes */}
      <EmpresaAdd
        visible={flagMdEmpresaAdd}
        setVisible={setflagMdEmpresaAdd}
        loadData={loadDataLocal}
        tipoDef={'requerimientos'}
      />

    </>
  );
};

export default TicketAdd;

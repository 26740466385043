import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Select, Switch } from "antd"
import { sendServer, toast } from '../../utils/utils'
import {
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons"
const { Option } = Select

const UsuarioAdd = ({ visible, setVisible, loadData }) => {

  const [form] = Form.useForm();
  const [empresas, setEmpresas] = useState([]);
  const [flagCmbEmpresas, setFlagCmbEmpresas] = useState(false);

  const loadDataLocal = async () => {
    try {
      const { data } = await sendServer(null, 'GET', `master/empresa/listar/true/null/null/null`);
      setEmpresas(data);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadDataLocal();
    setFlagCmbEmpresas(false);
    form.setFieldsValue({estado: true});
    return () => {}
  }, [visible])

  const handleSubmit = async (item) => {

    try {
      console.log(item)
      const { data } = await sendServer(item, 'POST', 'user/agregar');
      loadData();
      toast('success', 'fitconluapp', 'Agregado correctamente');
      closeModal();
    } catch (e) {
      const { status, data } = e;
      if (data === 'algunCampoDuplicado') {
        toast('warning', 'enexusapp', 'No se puede agregar, usuario duplicado');
      } else if (data === 'The given data was invalid.') {
        toast('warning', 'enexusapp', 'Faltan campos obligatorios');
      } else {
        toast('warning', 'enexusapp', `No se puede agregar - ${data}`);
      }
    }

  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  const handleCmbEmpresas = (val) => {
    if(val === 'cliente') {
      setFlagCmbEmpresas(true);
    } else {
      setFlagCmbEmpresas(false);
    }
  }

  return (
    <Modal
      title="Agregar usuario"
      visible={visible}
      width={1000}
      onOk={form.submit}
      onCancel={() => { closeModal() }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="usuario_add"
        onFinish={handleSubmit}
        form={form}
        autocomplete="off"
      >
        <Row gutter="24">

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Nombres"}
              labelCol={{ span: 24 }}
              name="nombres"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input size="large" placeholder="Nombres del usuario" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              name="rol"
              label="Rol"
              labelCol={{ span: 24 }}
              hasFeedback
              rules={[{ required: true, message: '¡Valor obligatorio!' }]}
            >
              <Select size="large" showSearch placeholder="Seleccione un rol" onChange={handleCmbEmpresas}>
                <Option value="user">user</Option>
                <Option value="admin">admin</Option>
                <Option value="cliente">cliente</Option>
              </Select>
            </Form.Item>
          </Col>

          {flagCmbEmpresas ?
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="idEmpresa"
                label="Empresa"
                labelCol={{ span: 24 }}
                rules={[{ required: false, message: '¡Valor obligatorio!' }]}
              >
                <Select
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Seleccione una empresa"
                >
                  {
                    empresas.map((op) => (
                      <Option key={op.id} value={op.id}>
                        {op.nombre}
                      </Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>
          :undefined}

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Nombre de usuario (Único)"}
              labelCol={{ span: 24 }}
              name="email"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input size="large" type="text" placeholder="Nombre de usuario" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Celular"}
              labelCol={{ span: 24 }}
              name="celular"
              rules={[
                { required: false, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input size="large" type="text" placeholder="Correo" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Correo asignado"}
              labelCol={{ span: 24 }}
              name="emailAsignado"
              rules={[
                { required: false, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input size="large" type="email" placeholder="Correo asignado" />
            </Form.Item>
          </Col>


          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Clave"}
              labelCol={{ span: 24 }}
              name="clave"
              rules={[
                { required: true, message: "¡Valor obligatorio!" },
              ]}
            >
              <Input size="large" type="password" placeholder="Clave" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Estado"}
              labelCol={{ span: 24 }}
              name="estado"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default UsuarioAdd;

import React, { useState } from 'react'
import Bitacora from './Bitacora'
import BitacoraAdd from './BitacoraAdd'

const Index = () => {

    const [flagShow, setFlagShow] = useState(true);
    const [dataUpd, setDataUpd] = useState({});
    
    return (
        flagShow ?
            <Bitacora flagShow={flagShow} setFlagShow={setFlagShow} setDataUpd={setDataUpd}></Bitacora>
        : <BitacoraAdd flagShow={flagShow} setFlagShow={setFlagShow} dataUpd={dataUpd} setDataUpd={setDataUpd}></BitacoraAdd>
    )
}

export default Index

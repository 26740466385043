import React, { useEffect, useState } from "react"
import { Modal, Form, Input, Row, Col, Switch, Select } from "antd"
import { sendServer, toast, getSesion } from '../../utils/utils'
import {
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons"
const { Option } = Select

const EmpresaAdd = ({ visible, setVisible, loadData, tipoDef }) => {

  const [form] = Form.useForm();
  const [flagBtn, setflagBtn] = useState(true);

  useEffect(() => {
    const sesion = getSesion();
    setflagBtn(sesion.rol === 'admin' ? false : true);
    form.setFieldsValue({ 
      estado: true,
      tipo: tipoDef
    });
    console.log('HIJO ADD');
  }, [visible])

  const handleSubmit = async (item) => {
    try {
      console.log(item)
      const { data } = await sendServer(item, 'POST', 'master/empresa/agregar');
      loadData(data);
      toast('success', 'enexusapp', 'Agregado correctamente');
      closeModal();
    } catch (e) {
      const { status, data } = e;
      if (data === 'algunCampoDuplicado') {
        toast('warning', 'enexusapp', 'No se puede agregar, usuario duplicado');
      } else if (data === 'The given data was invalid.') {
        toast('warning', 'enexusapp', 'Faltan campos obligatorios');
      } else {
        toast('warning', 'enexusapp', `No se puede agregar - ${data}`);
      }
    }

  };

  const closeModal = () => {
    form.resetFields();
    setVisible(false);
  }

  const pressBtnNombre = (input) => {
   const {charCode} = input;
   if(charCode === 13) {
    form.submit()
   }
  }

  return (
    <Modal
      title="Agregar cliente"
      visible={visible}
      width={1000}
      onOk={form.submit}
      okButtonProps={{ disabled: flagBtn }}
      onCancel={() => { closeModal() }}
      destroyOnClose={true}
      okText={"Guardar"}
      cancelText={"Cancelar"}
    >
      <Form
        name="empresa_add"
        onFinish={handleSubmit}
        form={form}
      >
        <Row gutter="24">

          {/* TIPO */}
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="tipo"
              label="Tipo"
              labelCol={{ span: 24 }}
              hasFeedback
              rules={[{ required: true, message: 'Por favor ingrese el tipo!' }]}
            >
              <Select
                size="large"
                showSearch
                optionFilterProp="children"
                placeholder="Seleccione el tipo"
              >
                {
                  window.tiposCliente.map((op) => (
                    op.valor !== 'todos' ?
                      <Option key={op.valor} value={op.valor}>
                        {op.nombre}
                      </Option>
                    : undefined  
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          {/* NOMBRE */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Nombres"}
              labelCol={{ span: 24 }}
              name="nombre"
              rules={[
                { required: true, message: "Por favor ingresa el nombre!" },
              ]}
            >
              <Input size="large" placeholder="Nombre del cliente" autoFocus onKeyPress={pressBtnNombre} />
            </Form.Item>
          </Col>

          {/* DOCUEMNTO */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Documento"}
              labelCol={{ span: 24 }}
              name="nit"
              rules={[
                { required: false, message: "Por favor ingresa el documento!" },
              ]}
            >
              <Input size="large" placeholder="Documento del cliente" />
            </Form.Item>
          </Col>
          
          {/* DOCUMENTO */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Correo"}
              labelCol={{ span: 24 }}
              name="correo"
              rules={[
                { required: false, message: "Por favor ingresa el correo!" },
              ]}
            >
              <Input size="large" type="email" placeholder="Correo del cliente" />
            </Form.Item>
          </Col>

          {/* TELEFONO */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Teléfono"}
              labelCol={{ span: 24 }}
              name="telefono"
              rules={[
                { required: false, message: "Por favor ingresa el teléfono!" },
              ]}
            >
              <Input size="large" type="text" placeholder="Teléfono del cliente" />
            </Form.Item>
          </Col>

          {/* DIRECCION */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Dirección"}
              labelCol={{ span: 24 }}
              name="direccion"
              rules={[
                { required: false, message: "Por favor ingresa la direccion!" },
              ]}
            >
              <Input size="large" type="text" placeholder="Dirección del cliente" />
            </Form.Item>
          </Col>

          {/* ESTADO */}
          <Col xs={24} sm={6} md={6}>
            <Form.Item
              label={"Estado"}
              labelCol={{ span: 24 }}
              name="estado"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
              />
            </Form.Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

export default EmpresaAdd;

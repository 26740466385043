import React, { useState, useEffect } from 'react'
import { sendServer, getSesion } from '../../utils/utils'
import { Button, Tag, Modal, Form, Row, Col, Select, Tooltip, Input } from 'antd'
import MaterialTable, { MTableToolbar } from 'material-table'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import { Divider, makeStyles } from "@material-ui/core"
import ExportExcel from './ExportExcel'
import {
    ReloadOutlined,
    FilterOutlined
} from "@ant-design/icons"
import moment from 'moment'
import 'moment/locale/es'
const { Option } = Select



const InformeMensual = () => {
    const useStyles = makeStyles({
        table: {
            '& tr:nth-child(even)': {
                background: '#dedede',
            },
        },
    });
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [state, setState] = useState(true);
    const [flagMdFiltro, setflagMdFiltro] = useState(false);
    const [tecnicos, settecnicos] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [estados, setEstados] = useState([]);
    const [filtro, setfiltro] = useState({
        query: 'todosXFecha',
        fechaInicial: '',
        fechaFinal: '',
        idTecnico: null,
        idEmpresa: null,
        estado: 'todos'
    });
    const [formFiltro] = Form.useForm();
    const sesion = getSesion();

    const loadData = async () => {
        try {
            setState(true);
            const { data } = await sendServer(null, 'GET', `master/bitacora/informeXFecha/${filtro.query}/${filtro.fechaInicial}/${filtro.fechaFinal}/${filtro.idTecnico}/${filtro.idEmpresa}/${filtro.estado}`);
            setData(data);
            setState(false);
        } catch (error) {
            setState(false);
        }
    }

    const loadSelectData = async () => {
        try {
            const { data: users } = await sendServer(null, 'GET', `user/listarSinClientes`);
            const { data: empresas } = await sendServer(null, 'GET', `master/empresa/listar/true/null/null/null`);
            const { data: estados } = await sendServer(null, 'GET', `master/estadoBitacora/listar`);

            const fechaInicial = moment().startOf('month').format('YYYY-MM-DD');
            const fechaFinal = moment().endOf('month').format('YYYY-MM-DD');
            users.push({id: -1, nombres: 'todos'});
            empresas.push({id: -1, nombre: 'todos'});
            estados.push({id: -1, nombre: 'todos'});
            setEstados(estados);
            settecnicos(users);
            setEmpresas(empresas);

            formFiltro.setFieldsValue({
                idTecnico: -1,
                fechaInicial,
                fechaFinal,
                idEmpresa: -1,
                estado: 'todos'
            })
        } catch (error) {
        }
    }

    const filtroDefault = () => {
        filtro.query = 'todosXFecha';
        filtro.fechaInicial = moment().startOf('month').format('YYYY-MM-DD');
        filtro.fechaFinal = moment().endOf('month').format('YYYY-MM-DD');
        filtro.estado = 'todos';
    }

    useEffect(() => {
        filtroDefault();
        loadData();
        loadSelectData();
        console.log('INFORME MENSUAL');
        return (() => {

        })
    }, [])

    /**
     * 
     * @param {*} item 
     */
    const filterServer = async (item) => {
        if(item.idTecnico === -1 && item.idEmpresa === -1) {
            filtro.idEmpresa = null;
            filtro.idTecnico = null;
            filtro.query = 'todosXFecha';
        } else if(item.idTecnico !== -1 && item.idEmpresa === -1) {
            filtro.idTecnico = item.idTecnico;  
            filtro.query = 'todosXFechaYTecnico';
        } else if(item.idTecnico !== -1 && item.idEmpresa !== -1) {
            filtro.idEmpresa = item.idEmpresa;
            filtro.idTecnico = item.idTecnico;  
            filtro.query = 'tecnicoEmpresa';
        } else if(item.idTecnico === -1 && item.idEmpresa !== -1) {
            filtro.idEmpresa = item.idEmpresa;
            filtro.query = 'empresaEstado';
        }
        filtro.fechaInicial = item.fechaInicial;
        filtro.fechaFinal = item.fechaFinal;
        filtro.estado = item.estado;
        loadData();
        setflagMdFiltro(false);
        console.log(filtro);
    }

    const handleBack = () => {
        window.history.back();
    }

    const sincronizar = () => {
        filtroDefault();
        loadData();
        loadSelectData();
    }

    const columns = [
        {
            title: '#ticket',
            field: 'id'
        },
        {
            title: 'técnico',
            field: 'nombreTecnico'
        },
        {
            title: 'cliente',
            field: 'nombreEmpresa'
        },
        {
            title: 'Requerimiento',
            field: 'requerimiento'
        },
        {
            title: 'solución',
            field: 'solucion'
        },
        {
            title: 'fehcaCrea',
            field: 'createdAt',
            render: fila => fila.createdAt ? <Tag color="blue">{fila.createdAt}</Tag> : 'Sin fecha'
        },
        {
            title: 'fechaProgramacion',
            field: 'fechaProgramacion',
            render: fila => fila.fechaProgramacion ? <Tag color="cyan">{fila.fechaProgramacion}</Tag> : 'Sin fecha'
        },
        {
            title: 'fechaCierre',
            field: 'fechaCierre',
            render: fila => fila.fechaCierre ? <Tag color="purple">{fila.fechaCierre}</Tag> : 'Sin fecha'
        },
        {
            title: 'tipo',
            field: 'tipo',
            render: fila => fila.tipo === 'ticketCorto' ? 'Ticket' : 'Bitácora'
        },
        {
            title: 'estado',
            field: 'estado',
            render: fila => {
                const estado = fila.estado;
                if (estado === 'activo') {
                    return (
                        <Tag color="#f50">{estado.toUpperCase()}</Tag>
                    )
                } else if (estado === 'finalizado') {
                    return (
                        <Tag color="#87d068">{estado.toUpperCase()}</Tag>
                    )
                } else if (estado === 'anulado') {
                    return (
                        <Tag color="#ff4d4d">{estado.toUpperCase()}</Tag>
                    )
                } else {
                    return (
                        <Tag color="#108ee9">{estado.toUpperCase()}</Tag>
                    )
                }
            }
        },
    ]

    return (
        <div className={classes.table}>
            <Button type="default"
                onClick={handleBack}
                icon={<FontAwesomeIcon icon={faHandPointLeft} />}
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>

            <MaterialTable
                title="Infome mensual"
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                options={{
                    pageSize: 10, 
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                /*detailPanel={rowData => {
                    return (
                        <>
                            <div style={{ margin: 5 }}>
                                <p style={{ fontWeight: 'bold' }}>requerimiento</p>
                                {rowData.requerimiento}
                            </div>
                            <Divider variant="fullWidth" />
                            <div style={{ margin: 7 }}>
                                <p style={{ fontWeight: 'bold' }}>solución</p>
                                {rowData.solucion}
                            </div>
                            <Divider variant="fullWidth" />
                            <div style={{ margin: 7 }}>
                                <p style={{ fontWeight: 'bold' }}>FechaCrea</p>
                                {moment(rowData.createdAt).format('YYYY-MM-DD h:mm a')}
                            </div>
                            <Divider variant="fullWidth" />
                            <div style={{ margin: 7 }}>
                                <p style={{ fontWeight: 'bold' }}>FechaCierre</p>
                                {rowData.fechaCierre ? moment(rowData.createdAt).format('YYYY-MM-DD h:mm a') : 'Sin cerrar'}
                            </div>
                        </>
                    );
                }}*/
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px', marginBottom: 10 }}>
                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={(sincronizar)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                                <Tooltip placement="top" title="Exportar">
                                   <ExportExcel dataSet={data}/>
                                </Tooltip>
                                <Tooltip placement="top" title="Filtrar">
                                    <Button size="middle" onClick={() => setflagMdFiltro(true)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <FilterOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    ),
                }}
            />


            {/* MODAL FILTRO */}
            <Modal
                title="Filtro informe mensual"
                visible={flagMdFiltro}
                width={1000}
                onOk={formFiltro.submit}
                onCancel={() => { setflagMdFiltro(false) }}
                destroyOnClose={true}
                okText={"Filtrar"}
                cancelText={"Cancelar"}
            >
                <Form
                    name="filtro"
                    onFinish={filterServer}
                    form={formFiltro}
                >
                    <Row gutter="24">
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item
                                name="idTecnico"
                                label="Técnico"
                                labelCol={{ span: 24 }}
                                hasFeedback
                                rules={[{ required: true, message: 'Por favor ingrese el técnico!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione el técnico"
                                >
                                    {
                                        tecnicos.map((op) => (
                                            <Option key={op.id} value={op.id}>
                                                {op.nombres}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item
                                name="idEmpresa"
                                label="Empresa"
                                labelCol={{ span: 24 }}
                                hasFeedback
                                rules={[{ required: true, message: 'Por favor ingrese la empresa!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione la empresa"
                                >
                                    {
                                        empresas.map((op) => (
                                            <Option key={op.id} value={op.id}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Form.Item
                                name="estado"
                                label="Estado"
                                labelCol={{ span: 24 }}
                                hasFeedback
                                rules={[{ required: true, message: 'Por favor ingrese el estado!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione el estado"
                                >
                                    {
                                        estados.map((op) => (
                                            <Option key={op.nombre} value={op.nombre}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label={"Fecha inicial"}
                                labelCol={{ span: 24 }}
                                name="fechaInicial"
                                rules={[
                                    { required: true, message: "Por favor ingresa la fecha inicial!" },
                                ]}
                            >
                                <Input type="date" style={{ width: '100%', height: '38px' }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                                label={"Fecha final"}
                                labelCol={{ span: 24 }}
                                name="fechaFinal"
                                rules={[
                                    { required: true, message: "Por favor ingresa la fecha final!" },
                                ]}
                            >
                                <Input type="date" style={{ width: '100%', height: '38px' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default InformeMensual

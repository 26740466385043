import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { sendServer, getSesion, savePropSesion, downloadPdf, toast, confirm } from '../../../utils/utils'
import { Button, Tag, Tooltip, Modal, Form, Row, Col, Select } from 'antd'
import MaterialTable, { MTableToolbar } from 'material-table'
import { makeStyles } from "@material-ui/core"
import {
    PlusCircleOutlined,
    ReloadOutlined,
    FilterOutlined,
    FilePdfOutlined
} from "@ant-design/icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
const { Option } = Select

let columns = [];

const HvPcs = () => {
    const useStyles = makeStyles({
        table: {
            '& tr:nth-child(even)': {
                background: '#dedede',
            },
        },
    });
    const classes = useStyles();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [state, setState] = useState(false);
    const [sesion, setSesion] = useState(getSesion());
    const [flagMdFiltro, setflagMdFiltro] = useState(false);
    const [formFiltro] = Form.useForm();
    const [empresas, setEmpresas] = useState([]);
    const [acctions, setAcctions] = useState([]);
    const [filtro, setfiltro] = useState({
        query: null,
        idEmpresa: null,
        estado: true
    })

    const loadData = async () => {
        try {
            const { id, rol, idEmpresa } = getSesion();
            filtro.query = 'todosXEmpresa';
            filtro.idEmpresa = idEmpresa;
            console.log(filtro)
            if (idEmpresa) {
                setState(true);
                const { data } = await sendServer(null, 'GET', `master/hv/listar/${filtro.query}/null/${filtro.idEmpresa}`);
                setData(data);
                setState(false);
            }
            const { data: empresas } = await sendServer(null, 'GET', `master/empresa/listar/true/null/null/null`);
            setEmpresas(empresas);
        } catch (error) {
            setState(false);
        }
    }

    useEffect(() => {
        validColumns();
        loadData();
    }, [])

    const upd = (data) => {
        const { id } = data;
        history.push(`/hv-editar-pcs/${id}`);
        console.log(data)
    }

    const add = () => {
        history.push('/hv-editar-pcs/-1');
    }

    const del = async (id) => {
        try {
            confirm('enexusapp', 'Desea eliminar? esta acción no se puede revertir', async () => {
                const { msg } = await sendServer(null, 'DELETE', `master/hv/eliminar/${id}`);
                toast('success', 'enexusapp', 'Anulado correctamente');
                loadData();
            });
        } catch (e) {
            toast('error', 'enexusapp', 'No se puede anular');
            console.log(e);
        }
    }

    /**
     * 
     * @param {*} item 
     */
    const filterServer = async (item) => {
        savePropSesion('idEmpresa', item.idEmpresa);
        loadData();
        setflagMdFiltro(false);
    }

    const handleBack = () => {
        window.history.back();
    }

    const sincronizar = () => {
        loadData();
    }

    const downloadReport = ({ id }) => {
        downloadPdf(id);
    }

    const validColumns = () => {
        const { rol } = getSesion();
        if (rol === 'cliente') {
            columns = [
                {
                    title: 'Hostname',
                    field: 'hostname'
                },
                {
                    title: 'Área',
                    field: 'nombreArea'
                },
                {
                    title: 'Marca',
                    field: 'marca',
                },
                {
                    title: 'Modelo',
                    field: 'modelo',
                },
                {
                    title: 'Serial',
                    field: 'serial',
                },
                {
                    title: 'Placa',
                    field: 'placa',
                },
            ];
        } else {
            columns = [
                {
                    title: 'Empresa',
                    field: 'nombreEmpresa'
                },
                {
                    title: 'Hostname',
                    field: 'hostname'
                },
                {
                    title: 'Área',
                    field: 'nombreArea'
                },
                {
                    title: 'Marca',
                    field: 'marca',
                },
                {
                    title: 'Modelo',
                    field: 'modelo',
                },
                {
                    title: 'Serial',
                    field: 'serial',
                },
                {
                    title: 'Placa',
                    field: 'placa',
                },
                {
                    title: 'EditadoX',
                    field: 'usuarioEdita'
                },
                {
                    title: 'Fecha edición',
                    field: 'updatedAt',
                    render: fila => {
                        return <Tag color="blue">{fila.updatedAt}</Tag>
                    }
                },
            ];
        }
        //valido los acctions de la tabla
        if(rol === 'admin') {
            setAcctions([
                {
                    icon: () => <FilePdfOutlined style={{ color: 'red' }} />,
                    iconProps: { style: { color: "#40a9ff" } },
                    tooltip: 'Exportar PDF',
                    onClick: (event, rowData) => downloadReport(rowData)
                },
                {
                    icon: 'delete',
                    iconProps: { style: { color: "#ff4d4f" } },
                    tooltip: 'Eliminar',
                    onClick: (event, rowData) => del(rowData.id),
                },
            ])
        } else{
            setAcctions([
                {
                    icon: () => <FilePdfOutlined style={{ color: 'red' }} />,
                    iconProps: { style: { color: "#40a9ff" } },
                    tooltip: 'Exportar PDF',
                    onClick: (event, rowData) => downloadReport(rowData)
                },
            ])
        }
    }


    return (
        <div className={classes.table}>
            <Button type="default"
                onClick={handleBack}
                icon={<FontAwesomeIcon icon={faHandPointLeft} />}
                style={{ marginBottom: 10, marginTop: 0, width: 100, display: 'block' }}>
            </Button>

            <MaterialTable
                title="Inventario PCS"
                onRowClick={(item, row) => { upd(row) }}
                columns={columns}
                data={data}
                key='id'
                isLoading={state}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: 'bold',
                        backgroundColor: '#01579b',
                        color: '#FFF',
                    },
                }}
                localization={{
                    header: {
                        actions: 'Acción',
                    },
                }}
                actions={acctions}
                components={{
                    Toolbar: props => (
                        <div>
                            <MTableToolbar {...props} />
                            <div style={{ padding: '0px 10px', marginBottom: 10 }}>
                                {
                                    sesion.rol !== 'cliente' ?
                                        <Tooltip placement="top" title="Agregar" >
                                            <Button size="middle" onClick={add}
                                                style={{ marginRight: 5 }} type="primary" ghost>
                                                <PlusCircleOutlined />
                                            </Button>
                                        </Tooltip>
                                        : undefined
                                }

                                <Tooltip placement="top" title="Sincronizar">
                                    <Button size="middle" onClick={(sincronizar)}
                                        style={{ marginRight: 5 }} type="primary" ghost>
                                        <ReloadOutlined />
                                    </Button>
                                </Tooltip>
                                {
                                    sesion.rol !== 'cliente' ?
                                        <Tooltip placement="top" title="Filtrar">
                                            <Button size="middle" onClick={() => setflagMdFiltro(true)}
                                                style={{ marginRight: 5 }} type="primary" ghost>
                                                <FilterOutlined />
                                            </Button>
                                        </Tooltip>
                                        : undefined
                                }
                            </div>
                        </div>
                    ),
                }}
            />

            {/* MODAL FILTRO */}
            <Modal
                title="Filtro PCS"
                visible={flagMdFiltro}
                width={1000}
                onOk={formFiltro.submit}
                onCancel={() => { setflagMdFiltro(false) }}
                destroyOnClose={true}
                okText={"Filtrar"}
                cancelText={"Cancelar"}
            >
                <Form
                    name="filtro"
                    onFinish={filterServer}
                    form={formFiltro}
                >
                    <Row gutter="24">
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item
                                name="idEmpresa"
                                label="Cliente"
                                labelCol={{ span: 24 }}
                                rules={[{ required: true, message: '¡Valor obligatorio!' }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Seleccione el cliente"
                                >
                                    {
                                        empresas.map((op) => (
                                            <Option key={op.id} value={op.id}>
                                                {op.nombre}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default HvPcs

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

//globales

//clientes
window.tiposCliente = [
  {
    nombre: 'contratos',
    valor: 'contratos'
  },
  {
    nombre: 'requerimientos',
    valor: 'requerimientos'
  },
  {
    nombre: 'cliente natural',
    valor: 'cliente natural'
  },
  {
    nombre: 'todos',
    valor: 'todos'
  }
]

//clientes
window.prioridades = [
  {
    nombre: 'alto',
    valor: 'alto'
  },
  {
    nombre: 'medio',
    valor: 'medio'
  },
  {
    nombre: 'bajo',
    valor: 'bajo'
  },
]

//ruta qr wpp
window.wppQr = process.env.REACT_APP_WPPQR;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

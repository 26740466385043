import "./style.css"
import logo from "../../img/logo.jpg"
import React, { useEffect } from "react"
import { Card, Col, Row, Form, Input, Button, Checkbox } from "antd"
import { UserOutlined, LockOutlined, SendOutlined } from "@ant-design/icons"
import useToken from '../../components/hooks/useToken'
import { sendServerLogin, toast } from '../../utils/utils'

const Login = () => {

  const { token, setToken } = useToken();

  if (token) {
    if(token.rol === 'cliente') {
      window.location = "/hv-pcs";
    } else {
      window.location = "/bitacora";
    }
  }

  const onFinish = async (user) => {

    try {
      const { status, data } = await sendServerLogin(user);
      if (status === 200) {
        setToken(data);
        if(data.rol === 'cliente') {
          window.location = "/hv-pcs";
        } else {
          window.location = "/bitacora";
        } 
      }
    } catch (e) {
      console.log(e)
      toast('info', 'enexusapp', 'No se puede iniciar');
    }
  };

  useEffect(() => {
    
    return () => {}
  }, [])

  return (
    <Row justify="center">
      <Col>
        <Card
          cover={
            <img
              className="loginImg"
              alt="enexus.com"
              src={logo}
            />
          }
          title="enexusapp"
          extra={<a href="https://www.enexus.com.co/">enexus.com.co</a>}
          className="cardLogin"
        >
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Por favor ingresa el Usuario!" },
              ]}
            >
              <Input
                style={{ borderRadius: 10 }}
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Usuario"
              />
            </Form.Item>
            <Form.Item
              name="clave"
              rules={[
                { required: true, message: "Por favor ingresa Contraseña!" },
              ]}
            >
              <Input
                style={{ borderRadius: 10 }}
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Contraseña"
              />
            </Form.Item>
            
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ width: '100%' }}
              >
                INICIAR
                <SendOutlined />
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;


import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Template from '../views/template/Template'


const PrivateRoute = ({ component: Component, ...data }) => {
    const { token: sesion, ruta } = data;
    //si no hay token lo saca
    if (!sesion) {
        return (
            <Route {...data} render={props => (
                <Redirect to="/" />
            )} />
        )
    }
    if (ruta === 'whatsapp' || ruta === 'usuarios') {
        if (sesion.rol === 'user') {
            return (
                <Route {...data} render={props => (
                    <Redirect to="/bitacora" />
                )} />
            )
        }
    }
    if(sesion.rol === 'cliente') {
        if (ruta !== 'hv-pcs' && ruta !== 'hv-editar-pcs') {
            return (
                <Route {...data} render={props => (
                    <Redirect to="/hv-pcs" />
                )} />
            )
        }
    }
    //valido modulos
    if(ruta === 'ticketCorto') {
        const { modulos } = sesion;
        if(modulos?.length) {
            for (let i = 0; i < modulos.length; i++) {
                const mod = modulos[i];
                if(mod.nombreModulo === ruta) {
                    return (
                        <Route {...data} render={props => (
                            <Template component={Component} {...data} />
                        )} />
                
                    );
                } else {
                    return (
                        <Route {...data} render={props => (
                            <Redirect to="/bitacora" />
                        )} />
                    )
                }
            }
        } else {
            return (
                <Route {...data} render={props => (
                    <Redirect to="/bitacora" />
                )} />
            )
        }
    }
    return (
        <Route {...data} render={props => (
            <Template component={Component} {...data} />
        )} />

    );
};

export default PrivateRoute;